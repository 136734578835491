import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {
  isChromeExtensionCompatible,
  useIsExtensionInstalled,
} from 'helpers/utils';
import {useCheckInstallation} from 'hooks/useCheckInstallation';
import {bool, func, object} from 'prop-types';
import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {InstallExtensionModal} from 'scenes/PokeBuilder/components/InstallExtensionModal';
import {buildUrlService} from 'services';
import './_Styles.scss';

export const MODE_FORM = 'FORM';
export const MODE_INSTALLATION = 'INSTALLATION';
export const MODE_PENDING = 'PENDING';
export const MODE_PICK_TRACKER_TYPE = 'PICK_TRACKER_TYPE';
export const MODE_EDIT = 'EDIT';

export const hasQueryParams = (url) => {
  return url.includes('?');
};

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  onOpenUrl: func,
  data: object,
};

const ModalLaunchBuilder = ({
  isOpen,
  onRequestClose = () => {},
  onOpenUrl = () => {},
  data = {},
  ...rest
}) => {
  const [url, setUrl] = useState('');
  const [urlInputFocused, setUrlInputFocused] = useState(false);
  const [mode, setMode] = useState(MODE_EDIT);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const {isInstalled: isExtensionInstalled, check} = useIsExtensionInstalled();
  let {isInstalled: isSnippetInstalled} = useCheckInstallation();

  const hasBuilderAccess =
    isSnippetInstalled === true || isExtensionInstalled === true;

  useEffect(() => {
    window.addEventListener('focus', check);
    return () => {
      window.removeEventListener('focus', check);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInvalidUrl === true) {
      try {
        new URL(url);
        setIsInvalidUrl(false);
      } catch (err) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const handleLaunchBuilder = async () => {
    if (url?.length > 0) {
      try {
        new URL(url);
      } catch (err) {
        setIsInvalidUrl(true);
        return;
      }
    }

    setIsLoading(true);

    if (url != null && url.length > 0) {
      await buildUrlService.createBuildUrl({url});
    }

    onOpenUrl(url);
    setMode(MODE_PENDING);
    setIsLoading(false);
  };

  const {data: buildUrls, refetch: refreshBuildUrls} = useQuery({
    queryKey: 'build-urls',
    queryFn: buildUrlService.getBuildUrls,
  });

  const handleDeleteUrl = async (urlId) => {
    try {
      await buildUrlService.deleteBuildUrl(urlId);
      toastSuccess('Url deleted', {toastId: 'url-detected'});
      refreshBuildUrls();
    } catch (err) {
      const {title, message, actions} = errorHelpers.parseError(err);

      toastDanger([title, message], {actions});
    }
  };

  const getTitle = () => {
    const hasBackButton = mode === MODE_INSTALLATION;

    let text = data?.title || 'Select in-app element';

    if (mode === MODE_INSTALLATION) {
      text = 'Install Jimo snippet';
    }
    if (mode === MODE_PENDING) {
      return null;
    }

    return (
      <>
        {hasBackButton && (
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => {}}
          />
        )}
        <div className="text">{text}</div>
      </>
    );
  };

  const filteredUrl = buildUrls?.filter((bu) => bu.url.includes(url || ''));

  let content, footer;

  if (isSnippetInstalled !== true && isExtensionInstalled == null) {
    return null;
  }

  if (
    isSnippetInstalled !== true &&
    isChromeExtensionCompatible() === true &&
    isExtensionInstalled !== true &&
    isOpen === true
  ) {
    return (
      <InstallExtensionModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={data?.installExtensionModalTitle}
      />
    );
  }

  if (mode === MODE_EDIT) {
    content = (
      <>
        <div className="modal-content">
          <div className="inputs-wrapper">
            <div className="section-title">Go to</div>
            <div className="tracker-url-wrapper">
              <div className="input-group-wrapper">
                <Input
                  autoComplete="off"
                  className="tracker-url"
                  placeholder="https://"
                  name="targetUrl"
                  type="url"
                  value={url}
                  iconRight={
                    isInvalidUrl ? 'icon-exclamation-circle' : 'icon-expand'
                  }
                  onChange={({target}) => setUrl(target.value)}
                  onFocus={() => setUrlInputFocused(true)}
                />
                {isInvalidUrl && (
                  <div className="body-4 r-400">
                    Please enter a valid URL, including the protocol (e.g.,
                    “https://”).
                  </div>
                )}
              </div>
              {filteredUrl?.length > 0 && (
                <Dropdown
                  className={classnames('dropdown-build-url-suggestions', {
                    'not-focused': urlInputFocused !== true,
                  })}
                  open={urlInputFocused === true}
                  onClose={() => setUrlInputFocused(false)}>
                  {filteredUrl
                    .sort(
                      (a, b) =>
                        new Date(b.lastUsed).getTime() -
                        new Date(a.lastUsed).getTime()
                    )
                    ?.map((bu) => {
                      return (
                        <div
                          className="url-suggestion"
                          onClick={() => {
                            setUrl(bu.url);
                            setUrlInputFocused(false);
                          }}>
                          {bu.url}
                          {/* For some reasons, using a Button here make the input buggy */}
                          <div
                            className="btn-delete-url"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleDeleteUrl(bu.uid);
                            }}>
                            <i className="icon-trash"></i>
                          </div>
                        </div>
                      );
                    })}
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      </>
    );

    footer = (
      <>
        <Button
          muted
          className="back-btn"
          type="button"
          onClick={() => {
            onRequestClose();
          }}>
          Cancel
        </Button>
        <Button
          primary
          disabled={hasBuilderAccess !== true || !url}
          loading={isLoading}
          onClick={() => handleLaunchBuilder()}>
          {data?.confirmLabel || 'Enter builder'}
        </Button>
      </>
    );
  }

  return (
    <Modal
      className={classnames('modal-launch-builder', mode?.toLowerCase(), {
        'no-max-height': mode === MODE_INSTALLATION,
      })}
      title={getTitle()}
      isOpen={isOpen}
      {...rest}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          zIndex: 1003,
        },
      }}
      footer={footer}>
      <div className={classnames('content-wrapper fade-in-right')}>
        {content}
      </div>
    </Modal>
  );
};

ModalLaunchBuilder.propTypes = propTypes;

export default ModalLaunchBuilder;
