import Button from 'components/Button';
import {func} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  onSelectElementClick: func.isRequired,
  onNavigateClick: func.isRequired,
};

const TargetNotFoundWarning = ({
  type,
  onSelectElementClick,
  onNavigateClick,
}) => {
  return (
    <div className="target-not-found">
      <div className="icon-wrapper">
        <i className="isax isax-info-circle" />
      </div>
      Your {type} is not found on this page
      <Button thin iconLeft="isax isax-gps" onClick={onSelectElementClick}>
        Reselect
      </Button>
      <Button
        thin
        primary
        iconLeft="isax isax-mouse-square"
        onClick={onNavigateClick}>
        Navigate
      </Button>
    </div>
  );
};

TargetNotFoundWarning.propTypes = propTypes;

export default TargetNotFoundWarning;
