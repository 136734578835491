import React from 'react';
import './_Styles.scss';
import EmptyState from './imgs/empty-state.svg';

const LogicViewEmptyState = () => {
  return (
    <div className="logic-view-empty-state">
      <img src={EmptyState} alt="Empty state" />
    </div>
  );
};

export default LogicViewEmptyState;
