import {default as classnames, default as classNames} from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import {ModalConfirm} from 'components/Modal';
import ModalInstall from 'components/ModalInstall';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {isPokeValid} from 'helpers/poke';
import {
  isChromeExtensionCompatible,
  useIsExtensionInstalled,
} from 'helpers/utils';
import {useCheckInstallation} from 'hooks/useCheckInstallation';
import {bool} from 'prop-types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_LIGHTWEIGHT_POKE_BUILDER,
  ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID,
  ROUTE_POKE_BUILDER_FROM_TYPE,
  ROUTE_POKE_SETTINGS_FROM_TYPE,
} from 'router/routes.const';
import {getPokeRoute} from 'scenes/PokeAudience/components/AudienceHeader';
import {isLogicViewCompatible} from 'scenes/PokeBuilder/utils';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_CHECKLIST,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {Swaler} from 'swaler';
import AdoptionStepsManager from '../AdoptionStepsManager';
import ChecklistStepsManager from '../ChecklistStepsManager';
import DiscoveryStepsManager from '../DiscoveryStepsManager';
import {
  ImproveBuildingModal,
  InstallExtensionModal,
  NotOptimalBrowserModal,
} from '../InstallExtensionModal';
import LiveEditWarningModal from '../LiveEditWarningModal';
import PreviewSelector from '../PreviewSelector';
import PublishChangesModal from '../PublishChangesModal';
import TroubleShootingMenu from '../TroubleshootingMenu';
import './_Styles.scss';
import BuilderModeSwitch from './components/BuilderModeSwitch';
import Logo from './imgs/logo.png';

const logger = new Swaler('PokeBuilderHeader');

const propTypes = {
  isFetching: bool,
  isInApp: bool,
  isLoading: bool,
};

const defaultProps = {
  isFetching: false,
  isInApp: false,
  isLoading: false,
};

const PokeBuilderHeader = ({isFetching, isInApp}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const isExtension = query.get('is-extension') != null;

  const project = useSelector((state) => generalSelector.getProject(state));

  const {
    evolution,
    setEvolution,
    originalEvolution,
    setOriginalEvolution,
    hasUnSaveChanges,
    refetchEvolution,
    messenger,
    setIsInInteractivePreview,
    lastAutoSaveAt,
  } = useContext(BuilderContext);
  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [showModalInstall, setShowModalInstall] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [troubleshootingMenuOpen, setTroubleshootingMenuOpen] = useState(false);
  const [showLiveEditWarningModal, setShowLiveEditWarningModal] =
    useState(false);
  const [showPublishChangesModal, setShowPublishChangesModal] = useState(false);
  const [isAutoSaveTooltipActive, setIsAutoSaveTooltipActive] = useState(false);
  const [isAutoSaveTooltipOpen, setIsAutoSaveTooltipOpen] = useState(false);
  const [showNotOptimalBrowserModal, setShowNotOptimalBrowserModal] =
    useState(false);
  const [showMissingExtensionModal, setShowMissingExtensionModal] =
    useState(false);

  const refInputTitle = useRef();
  const timeoutLastAutoSave = useRef();

  const issues = isPokeValid(evolution);
  const isSubmitDisabled = issues !== true;

  const {isInstalled: isExtensionInstalled, check} = useIsExtensionInstalled();
  let {isInstalled: isSnippetInstalled} = useCheckInstallation();

  useEffect(() => {
    window.addEventListener('focus', check);
    return () => {
      window.removeEventListener('focus', check);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isExtensionInstalled === true && showMissingExtensionModal === true) {
      setShowMissingExtensionModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtensionInstalled]);

  useEffect(() => {
    if (lastAutoSaveAt != null) {
      clearTimeout(timeoutLastAutoSave.current);
      setIsAutoSaveTooltipActive(true);
      timeoutLastAutoSave.current = setTimeout(() => {
        setIsAutoSaveTooltipActive(false);
      }, 2000);
    }
  }, [lastAutoSaveAt]);

  useEffect(() => {
    if (isSubmitDisabled !== true) {
      setTroubleshootingMenuOpen(false);
    }
  }, [isSubmitDisabled]);

  useEffect(() => {
    if (isInApp === true) {
      messenger?.sendInAppForegroundForce(troubleshootingMenuOpen === true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [troubleshootingMenuOpen]);

  const handleEditInApp = async () => {
    goToEditInApp({
      data: {
        title: 'Edit in-app',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'POKE_BUILDER',
          pokeId: evolution.uid,
          project,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'EVOLUTION_SAVED') {
          // using message.data.evolution.context instead of context cause
          // context is null when clicking target & publish in in-app builder
          // which  prevent redirection in the parent tab after closing the in-app builder
          if (
            [
              EVOLUTION_TYPE_TOUR,
              EVOLUTION_TYPE_SURVEY,
              EVOLUTION_TYPE_BANNER,
              EVOLUTION_TYPE_HINT,
            ].includes(message.data.evolution.type)
          ) {
            history.push(
              ROUTE_POKE_SETTINGS_FROM_TYPE(
                message.data.evolution.uid,
                message.data.evolution.type
              )
            );
          }
          stopInAppEditing();
        }
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
        if (message?.type === 'PREVIEW_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  const handlePreview = async () => {
    if (isInApp === true) {
      return;
    }

    setIsPreviewing(true);

    try {
      goToEditInApp({
        data: {
          title: 'Preview',
          confirmLabel: 'Launch preview',
          installExtensionModalTitle: 'Ready to preview your experience?',
        },
        handshakeData: {
          type: 'SET_MODE',
          data: {
            mode: 'POKE_PREVIEW',
            pokeId: evolution.uid,
            evolution: evolution,
            project: project,
          },
        },
        onChildCommunication: (message) => {
          if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
            stopInAppEditing();
          }
          if (message?.type === 'PREVIEW_CLOSE') {
            stopInAppEditing();
          }
        },
      });
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsPreviewing(false);
    }
  };

  const savePoke = async () => {
    setIsSaving(true);
    let success = false;
    try {
      await evolutionService.updateEvolution(evolution.uid, {
        ...evolution,
      });
      await refetchEvolution();
      toastSuccess('Changes saved!', {toastId: 'changes-saved'});
      success = true;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }

    return success;
  };

  const handleTargetAndPublishClick = async () => {
    setIsSubmitting(true);
    const success = await savePoke();
    setIsSubmitting(false);

    if (success === true) {
      refetchEvolution();
      if (isInApp === true) {
        messenger.sendEvolutionSaved({evolution});
      } else {
        if (evolution.isDraft === true) {
          history.push(
            ROUTE_POKE_SETTINGS_FROM_TYPE(evolution.uid, evolution.type)
          );
        } else {
          const route = getPokeRoute(evolution.type);
          history.push(route(evolution.uid));
        }
      }
    }
  };

  const handleUpdateTitle = async () => {
    try {
      await evolutionService.updateEvolutionTitle(
        evolution.uid,
        evolution.title
      );
      setOriginalEvolution({...originalEvolution, title: evolution.title});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Update evolution title', code);
      toastDanger([title, message], {actions});
    }
  };

  const handleNavigate = ({previewAfterNavigation = false} = {}) => {
    messenger?.sendSwitchToNavigatorMode(previewAfterNavigation);
  };

  const {type} = evolution;

  const isStepBuilder = [
    ROUTE_POKE_BUILDER_FROM_TYPE(),
    ROUTE_LIGHTWEIGHT_POKE_BUILDER,
    ROUTE_LIGHTWEIGHT_POKE_BUILDER_WITH_ID(),
  ].includes(match.path);

  const isTour = type === EVOLUTION_TYPE_TOUR;
  const isSurvey = type === EVOLUTION_TYPE_SURVEY;
  const isBanner = type === EVOLUTION_TYPE_BANNER;
  const isHint = type === EVOLUTION_TYPE_HINT;
  const isChecklist = type === EVOLUTION_TYPE_CHECKLIST;

  const isCompatibleWithLogicView = isLogicViewCompatible(evolution);

  const isLogicView =
    (isTour || isSurvey) && isCompatibleWithLogicView === true;

  const isBrowserOptimal = isChromeExtensionCompatible();

  return (
    <div
      className="poke-builder-header"
      onMouseEnter={() => messenger?.sendInAppForegroundForce(true)}
      onMouseLeave={() => messenger?.sendInAppForegroundForce(false)}>
      <div className="left-wrapper">
        {isExtension === true ? (
          <Tooltip
            className="tooltip-poke-builder-header"
            trigger={
              <div>
                <Button
                  thin
                  className="btn-exit-extension"
                  loading={isPreviewing}
                  disabled={isSaving || isSubmitting || isPreviewing}
                  iconLeft="icon-close"
                  iconOnly
                  onClick={() => messenger.sendCloseEmbeddedBuilder()}
                />
              </div>
            }
            offsetY={4}
            offsetX={14}>
            Exit builder
          </Tooltip>
        ) : isInApp === true ? (
          <div className={classnames('logo-wrapper', {'with-border': true})}>
            <img src={Logo} alt="Logo" className="logo" />
          </div>
        ) : (
          <></>
        )}
        {isInApp === false && (
          <Button
            thin
            className="btn-back"
            iconLeft="icon-arrow-left"
            iconOnly
            tertiary
            onClick={() => {
              const route = getPokeRoute(evolution.type);
              if (isStepBuilder === true) {
                hasUnSaveChanges()
                  ? setShowExitModal(true)
                  : history.push(route(evolution.uid));
              } else {
                history.push(ROUTE_POKE_BUILDER_FROM_TYPE(evolution.uid));
              }
            }}
          />
        )}
        <div className="name-wrapper">
          {type?.charAt(0)?.toUpperCase() + type?.slice(1)?.toLowerCase()}/
          {isFetching === false && (
            <Input
              ref={refInputTitle}
              className="input-name"
              muted
              value={evolution.title}
              onPressEnter={() => {
                refInputTitle.current.blur();
              }}
              onChange={({target}) =>
                setEvolution({...evolution, title: target.value})
              }
              onBlur={() => handleUpdateTitle()}></Input>
          )}
        </div>
      </div>
      <div className="center-wrapper">
        {isStepBuilder === true && (
          <>
            {/* If is in-app */}
            {isInApp === true ? (
              isSurvey ? (
                <DiscoveryStepsManager />
              ) : (
                <AdoptionStepsManager />
              )
            ) : (
              // If is not in-app
              <>
                {isLogicView === true ? (
                  <BuilderModeSwitch />
                ) : isChecklist === true ? (
                  <ChecklistStepsManager />
                ) : isTour === true || isBanner === true || isHint === true ? (
                  <AdoptionStepsManager />
                ) : (
                  <DiscoveryStepsManager />
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className="right-wrapper">
        {evolution.isDraft === true && (
          <Tooltip
            key={lastAutoSaveAt}
            className="tooltip-auto-save"
            arrow
            arrowStyle={{
              color: '#159201',
              width: '12px',
              height: '8px',
            }}
            disabled={
              isAutoSaveTooltipActive !== true && isAutoSaveTooltipOpen !== true
            }
            onOpen={() => setIsAutoSaveTooltipOpen(true)}
            onClose={() => setIsAutoSaveTooltipOpen(false)}
            trigger={
              <div key={lastAutoSaveAt} className="last-auto-save">
                <i className="icon-tick" />
              </div>
            }
            offsetY={2}
            offsetX={4}>
            Work automatically saved!
          </Tooltip>
        )}

        {isLogicView === true && isBrowserOptimal !== true ? (
          <div
            className="safe-to-edit-wrapper o-500"
            onClick={() => setShowNotOptimalBrowserModal(true)}>
            <i className="icon-info-circle-o" />
            <span>This browser is not optimal</span>
          </div>
        ) : isLogicView === true && isExtensionInstalled !== true ? (
          <div
            className="safe-to-edit-wrapper o-500"
            onClick={() => setShowMissingExtensionModal(true)}>
            <i className="icon-info-circle-o" />
            <span>Missing extension</span>
          </div>
        ) : evolution.isDraft !== true ? (
          <div
            className="safe-to-edit-wrapper o-500"
            onClick={() => setShowLiveEditWarningModal(true)}>
            <i className="icon-info-circle-o" />
            <span>What's safe to edit?</span>
          </div>
        ) : (
          <></>
        )}

        {evolution.isDraft === true && (
          <Button
            thin
            className="btn-save"
            loading={isSaving && !isSubmitting}
            disabled={isSubmitting || isPreviewing}
            onClick={savePoke}>
            Save
          </Button>
        )}
        {/* )} */}
        {isStepBuilder === true && (
          <>
            {isInApp !== true && isChecklist !== true && (
              <Button
                thin
                className="btn-edit-in-app"
                loading={isPreviewing}
                disabled={isSaving || isSubmitting}
                onClick={handleEditInApp}>
                Edit in-app
              </Button>
            )}
            {isInApp === true && (
              <Tooltip
                className="tooltip-poke-builder-header"
                arrow
                arrowStyle={{
                  color: '#080f25',
                  width: '12px',
                  height: '8px',
                }}
                trigger={
                  <div>
                    <Button
                      thin
                      className="btn-navigate"
                      disabled={isPreviewing || isSaving || isSubmitting}
                      iconLeft="icon-pointer"
                      iconOnly
                      onClick={() => handleNavigate()}
                    />
                  </div>
                }
                offsetY={2}>
                Interact with my app
              </Tooltip>
            )}
            <Dropdown
              className="preview-dropdown-wrapper"
              on={['hover']}
              offsetY={4}
              disabled={isInApp !== true}
              trigger={
                <div>
                  <Button
                    thin
                    className="btn-play"
                    loading={isPreviewing}
                    disabled={isSaving || isSubmitting}
                    iconLeft="isax isax-arrow-right-4"
                    iconOnly
                    onClick={handlePreview}>
                    Preview
                  </Button>
                </div>
              }>
              <PreviewSelector
                evolution={evolution}
                onStartPreview={() => {
                  messenger.sendStartPreview();
                  setIsInInteractivePreview(true);
                }}
                onStartFromOrigin={() => {
                  messenger.sendNavigateTo({
                    url: evolution.boostedActiveUrl,
                    previewAfterNavigation: true,
                  });
                  setIsInInteractivePreview(true);
                }}
                onNavigateToPage={() => {
                  handleNavigate({previewAfterNavigation: true});
                  setIsInInteractivePreview(true);
                }}
              />
            </Dropdown>

            {isSubmitDisabled !== true ? (
              <Button
                reverted
                thin
                primary
                className="btn-target"
                loading={isSubmitting}
                disabled={isSubmitDisabled || isSaving || isPreviewing}
                onClick={() => {
                  if (evolution.isDraft !== true) {
                    setShowPublishChangesModal('publish');
                    return;
                  }

                  handleTargetAndPublishClick();
                }}>
                {evolution.isDraft === true
                  ? 'Target & publish'
                  : 'Publish changes'}
              </Button>
            ) : (
              <Dropdown
                className="dropdown-move-to"
                position="bottom right"
                open={troubleshootingMenuOpen}
                offsetY={4}
                on={['hover']}
                onClose={() => setTroubleshootingMenuOpen(false)}
                trigger={
                  <Button
                    reverted
                    thin
                    primary
                    className={classNames('btn-target', {
                      'is-invalid': isSubmitDisabled,
                    })}
                    loading={isSubmitting}
                    disabled={isSaving || isPreviewing}
                    onClick={() => setTroubleshootingMenuOpen(true)}>
                    {evolution.isDraft === true
                      ? 'Target & publish'
                      : 'Publish changes'}
                    <div className="icon-wrapper">
                      <i className="icon-exclamation-triangle-o" />
                    </div>
                  </Button>
                }>
                <TroubleShootingMenu
                  issues={issues}
                  onClose={() => setTroubleshootingMenuOpen(false)}
                />
              </Dropdown>
            )}
          </>
        )}
      </div>
      <ModalInstall
        isOpen={showModalInstall}
        onRequestClose={() => setShowModalInstall(false)}
      />
      <ModalConfirm
        className="exit-modal"
        title="Exit without saving?"
        isOpen={showExitModal}
        onConfirm={() => {
          setShowExitModal(false);
          const route = getPokeRoute(evolution.type);
          history.push(route(evolution.uid));
        }}
        onCancel={() => setShowExitModal(false)}
        cancelText="Cancel"
        confirmText="Exit"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          You have unsaved changes, are you sure you want to exit without
          saving?
        </div>
      </ModalConfirm>

      {/* 
        Modals
      */}
      {showLiveEditWarningModal && (
        <LiveEditWarningModal
          isOpen={showLiveEditWarningModal}
          onRequestClose={() => setShowLiveEditWarningModal(false)}
          closable
        />
      )}

      {showPublishChangesModal !== false && (
        <PublishChangesModal
          isOpen={showPublishChangesModal !== false}
          onRequestClose={() => setShowPublishChangesModal(false)}
          onConfirm={() => {
            if (showPublishChangesModal === 'save') {
              savePoke();
            } else {
              handleTargetAndPublishClick();
            }
            setShowPublishChangesModal(false);
          }}
        />
      )}

      {showNotOptimalBrowserModal === true && (
        <NotOptimalBrowserModal
          isOpen={showNotOptimalBrowserModal}
          onRequestClose={() => setShowNotOptimalBrowserModal(false)}
        />
      )}

      {showMissingExtensionModal === true && (
        <>
          {isSnippetInstalled !== true ? (
            <InstallExtensionModal
              isOpen={showMissingExtensionModal}
              onRequestClose={() => setShowMissingExtensionModal(false)}
              title="Extension is missing"
            />
          ) : (
            <ImproveBuildingModal
              isOpen={showMissingExtensionModal}
              onRequestClose={() => setShowMissingExtensionModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

PokeBuilderHeader.propTypes = propTypes;
PokeBuilderHeader.defaultProps = defaultProps;

export default PokeBuilderHeader;
