const {F_BOOST_SLOT_NAVIGATION} = require('services/evolution');

export const isLogicViewCompatible = (evolution) => {
  const hasGroupedSteps = evolution.tourSteps?.some((ts) => {
    return ts.steps?.length > 1;
  });

  const hasNavigationStep = evolution.tourSteps?.some((ts) => {
    return ts.boostFlags === F_BOOST_SLOT_NAVIGATION;
  });

  const hasConceptTestSubSteps = evolution.steps?.some((step) => {
    return step.prototypes[0]?.steps?.length > 0;
  });

  return (
    hasGroupedSteps !== true &&
    hasNavigationStep !== true &&
    hasConceptTestSubSteps !== true
  );
};
