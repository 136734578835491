import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import React, {memo, useContext, useMemo} from 'react';
import {Handle, Position} from 'reactflow';
import {
  BLOCK_TYPE_PRIMARY_CTA,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import {LogicViewContext} from '..';
import './_Styles.scss';
import Step from './components/Step';

const StepNode = ({data}) => {
  const {steps} = useContext(LogicViewContext);
  const {selectedStepId} = useContext(BuilderContext);

  const hasHandle = useMemo(() => {
    const index = steps.findIndex((step) => step.uid === data.step.uid);
    const nextStep = steps[index + 1];
    const hasPrimaryCta = data.step.blocks.some(
      (block) => block.type === BLOCK_TYPE_PRIMARY_CTA && block.removed !== true
    );

    const canAddConditions = [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
      STEP_TYPE_NPS,
      STEP_TYPE_SLIDER,
      STEP_TYPE_OPINION_SCALE,
      STEP_TYPE_TEXT_LONG,
    ].includes(data.step.type);

    return (
      canAddConditions === true &&
      hasPrimaryCta !== true &&
      data.step.endSurvey !== true &&
      nextStep != null
    );
  }, [
    data.step.blocks,
    data.step.endSurvey,
    data.step.type,
    data.step.uid,
    steps,
  ]);

  const wrapperClassName = useMemo(() => {
    return classNames('step-node-wrapper', {
      'is-selected': selectedStepId === data.step.uid,
    });
  }, [selectedStepId, data.step.uid]);

  return (
    <div className={wrapperClassName}>
      <Handle type="target" position={Position.Left} />
      {hasHandle && (
        <Handle
          type="source"
          position={Position.Right}
          id={`${data.step.uid}-default-handle`}
          isConnectable={false}
          isConnectableStart={false}
        />
      )}
      <Step data={data} isSelected={selectedStepId === data.step.uid} />
    </div>
  );
};

export default memo(StepNode);
