import Button from 'components/Button';
import InputGroup from 'components/Input';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Tooltip from 'components/Tooltip';
import {errorHelpers} from 'helpers';
import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTE_SUCCESS_TRACKER, ROUTE_TRACKER_REPORT} from 'router/routes.const';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {hasConditionInLogic} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {
  CREATE_TRACKER_ISSUE,
  TrackerBuilderContext,
} from 'scenes/TrackerBuilder';
import {trackerService} from 'services';
import {Swaler} from 'swaler';
import {v4 as uuidv4} from 'uuid';
import './_Styles.scss';

const logger = new Swaler('TrackerBuilder');

const TrackerBuilderHeader = () => {
  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);
  const {tracker, setTracker, isEditingAudience, isValid, issue} = useContext(
    TrackerBuilderContext
  );

  const history = useHistory();

  const handleSave = async () => {
    try {
      if (tracker.onTheFlyEvent != null) {
        tracker.onTheFlyEvent.conditions =
          tracker.onTheFlyEvent.conditions.filter((c) =>
            hasConditionInLogic(tracker.onTheFlyEvent.logic, c.uid)
          );
      }
      tracker.steps?.forEach((step, index) => {
        step.indexOrder = index;
        if (step.onTheFlyEvent != null) {
          step.onTheFlyEvent.conditions = step.onTheFlyEvent.conditions.filter(
            (c) => hasConditionInLogic(step.onTheFlyEvent.logic, c.uid)
          );
        }
      });
      const createdTracker = await trackerService.createTracker(tracker);
      history.push(ROUTE_TRACKER_REPORT(createdTracker.uid));
      toastSuccess('Tracker created 👍', {toastId: 'tracker-created'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Failed creating tracker: ${code}`);
      toastDanger([title, message], {actions});
    }
  };

  const testableTracker = JSON.parse(JSON.stringify(tracker));

  if (testableTracker.onTheFlyEvent != null) {
    testableTracker.onTheFlyEvent.conditions =
      testableTracker.onTheFlyEvent.conditions.filter((c) =>
        hasConditionInLogic(testableTracker.onTheFlyEvent.logic, c.uid)
      );
  }
  testableTracker.steps?.forEach((step, index) => {
    step.indexOrder = index;
    if (step.onTheFlyEvent != null) {
      step.onTheFlyEvent.uid = uuidv4(); // Generate a new uid for the onTheFlyEvent to correctly validate triggered events when testing
      step.onTheFlyEvent.conditions = step.onTheFlyEvent.conditions.filter(
        (c) => hasConditionInLogic(step.onTheFlyEvent.logic, c.uid)
      );
    }
  });

  const handleTestTracker = async () => {
    goToEditInApp({
      data: {
        title: 'Test your tracker',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'EVENT_TESTER',
          tracker: testableTracker,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  let tooltipTitle, tooltipTestContent, tooltipContent;

  if (isEditingAudience === true) {
    tooltipTitle = 'You have unsaved audience changes';
    tooltipContent =
      'You need to finish editing the audience to create your tracker.';
  } else if (issue === CREATE_TRACKER_ISSUE.NO_STEPS) {
    tooltipTitle = 'Your tracker is missing steps';
    tooltipTestContent =
      'You need at least 2 steps to test your activation tracker.';
    tooltipContent =
      'You need at least 2 steps to create an activation tracker.';
  } else if (issue === CREATE_TRACKER_ISSUE.NO_EVENTS) {
    tooltipTitle = 'Your tracker is missing events';
    tooltipTestContent =
      'You need to add missing event(s) to test your tracker.';
    tooltipContent = 'You need to add missing event(s) to create your tracker.';
  } else if (issue === CREATE_TRACKER_ISSUE.INVALID_EVENT) {
    tooltipTitle = 'Your tracker has an invalid event';
    tooltipTestContent =
      'You need to fix the invalid event(s) to test your tracker.';
    tooltipContent =
      'You need to fix the invalid event(s) to create your tracker.';
  }

  return (
    <div className="tracker-builder-header">
      <Button
        className="back-btn"
        iconOnly
        iconLeft="icon-chevron-left"
        onClick={async () => {
          history.push(ROUTE_SUCCESS_TRACKER);
        }}
      />
      <InputGroup
        className="tracker-name-input"
        placeholder="Tracker name"
        value={tracker.name}
        onChange={(e) => {
          setTracker({
            ...tracker,
            name: e.target.value,
          });
        }}
      />
      <div className="actions">
        <Tooltip
          className="create-tracker-tooltip"
          disabled={isValid === true}
          position="bottom"
          offsetY={8}
          dark
          trigger={
            <div>
              <Button
                className="btn-test"
                primary={isValid !== true}
                iconLeft="isax isax-mouse-square"
                disabled={isValid !== true}
                onClick={handleTestTracker}>
                Test tracker
              </Button>
            </div>
          }>
          <i className="icon-info-circle-o" />
          <div className="content">
            <div className="subtitle-3">{tooltipTitle}</div>
            <div className="body-3">{tooltipTestContent}</div>
          </div>
        </Tooltip>
        <Tooltip
          className="create-tracker-tooltip"
          disabled={isEditingAudience !== true && isValid === true}
          position="bottom right"
          offsetY={8}
          dark
          trigger={
            <div>
              <Button
                className="save-btn"
                primary
                disabled={isEditingAudience === true || isValid !== true}
                iconRight="icon-arrow-right"
                onClick={() => handleSave()}>
                Create tracker
              </Button>
            </div>
          }>
          <i className="icon-info-circle-o" />
          <div className="content">
            <div className="subtitle-3">{tooltipTitle}</div>
            <div className="body-3">{tooltipContent}</div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default TrackerBuilderHeader;
