import Axios from 'axios';

// Endpoints
const EP_PROTOTYPE_CREATE = '/prototype';
const EP_PROTOTYPE_FILE_CREATE = (prototypeId) =>
  `/prototype/${prototypeId}/file`;
const EP_PROTOTYPE_FILE_DELETE = (prototypeId, prototypeFileId) =>
  `/prototype/${prototypeId}/file/${prototypeFileId}`;
const EP_PROTOTYPE_UPDATE = (prototypeId) => `/prototype/${prototypeId}`;

export const createPrototype = (data) => {
  const {evolutionId, link = null} = data;

  return Axios.post(EP_PROTOTYPE_CREATE, {link}, {params: {evolutionId}}).then(
    (response) => response.data
  );
};

export const updatePrototype = (prototypeId, data) => {
  const {interactiveLink} = data;

  return Axios.put(EP_PROTOTYPE_UPDATE(prototypeId), {interactiveLink}).then(
    (response) => response.data
  );
};

export const createPrototypeFile = (data) => {
  const {prototypeId, file} = data;
  const formData = new FormData();

  formData.append('file', file);
  return Axios.post(EP_PROTOTYPE_FILE_CREATE(prototypeId), formData, {
    headers: {'Content-Type': 'multipart/form-data'},
  }).then((response) => response.data);
};

export const deletePrototypeFile = (prototypeId, prototypeFileId) => {
  return Axios.delete(
    EP_PROTOTYPE_FILE_DELETE(prototypeId, prototypeFileId)
  ).then((response) => response.data);
};
