import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
import Select from 'components/Select';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import {PermissionsSettings} from 'constants/permissions';
import {errorHelpers} from 'helpers';
import {addFlag, hasFlag, reverseFlag} from 'helpers/bitwise';
import {isCorporateEmail} from 'helpers/email';
import {errorCodes} from 'helpers/error';
import {hasPermissions} from 'helpers/permission';
import {useUpdateSubscription} from 'hooks/useUpdateSubscription';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {SettingSubNav} from 'scenes/Settings/components/SubNav';
import {dataSelector, generalSelector} from 'selectors';
import {projectService} from 'services';
import {
  F_EXTRA_ALLOW_SAME_DOMAIN_EMAIL_JOIN,
  F_GET_STARTED_INVITE_TEAM,
  PROJECT_ROLE_ADMIN,
  PROJECT_ROLE_CUSTOM,
  PROJECT_ROLE_VIEWER,
} from 'services/project';
import {getRoleNameBySlug} from 'services/roles';
import {
  PLAN_GROWTH_ID,
  PLAN_SCALE_ID,
  PLAN_STARTUP_ID,
} from 'services/subscription';
import {Swaler} from 'swaler';
import './_Styles.scss';
import {RolesManager} from './roles-manager';

const TAB_MEMBERS = 'members';
const TAB_ROLES = 'roles';

const logger = new Swaler('Settings/Team');

const Team = () => {
  const dispatch = useDispatch();
  const {update: updateSubscription} = useUpdateSubscription();

  const project = useSelector((state) => generalSelector.getProject(state));
  const projectMember = useSelector((state) =>
    generalSelector.getProjectMember(state)
  );
  const user = useSelector((state) => generalSelector.getUser(state));
  const plans = useSelector((state) =>
    dataSelector.getSubscriptionsPlans(state)
  );
  const customRoles = useSelector((state) =>
    dataSelector.getCustomRoles(state)
  );
  const builtInRoles = useSelector((state) =>
    dataSelector.getBuiltInRoles(state)
  );

  const uptProject = (data) => dispatch(generalActions.uptProject(data));
  const uptProjectMember = (data) =>
    dispatch(generalActions.uptProjectMember(data));

  const selectRoles = builtInRoles
    .map((r) => ({
      ...r,
      name: getRoleNameBySlug(r.slug),
      uid: r.slug,
    }))
    .concat(customRoles)
    .map((r) => ({value: r.uid, content: r.name}));
  const planStartup = plans.find((p) => p.uid === PLAN_STARTUP_ID);
  const planGrowth = plans.find((p) => p.uid === PLAN_GROWTH_ID);
  const [isLoadingMembers, setIsLoadingMembers] = useState(true);
  const [isDeletingInvitation, setIsDeletingInvitation] = useState(null);
  const [isDeletingMember, setIsDeletingMember] = useState(null);
  const [isUpdatingMember, setIsUpdatingMember] = useState(null);
  const [isInviting, setIsInviting] = useState(false);
  const [members, setMembers] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [tab, setTab] = useState(TAB_MEMBERS);
  const [inputEmail, setInputEmail] = useState('');
  const [roleToInvite, setRoleToInvite] = useState(
    selectRoles.find((r) => r.value === PROJECT_ROLE_ADMIN)?.value
  );

  useEffect(() => {
    const setup = async () => {
      try {
        await fetchMembers();
        await fetchInvitations();
      } catch (err) {
        logger.error(err);
      }
      setIsLoadingMembers(false);
    };

    setup();
  }, []);

  const fetchMembers = async () => {
    try {
      const members = await projectService.getProjectMembers();

      setMembers(members);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Fetching members failed with error`, code);
      return toastDanger([title, message], {actions});
    }
  };
  const fetchInvitations = async () => {
    try {
      const invitations = await projectService.getProjectMemberInvitations();

      setInvitations(invitations);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Fetching members failed with error`, code);
      return toastDanger([title, message], {actions});
    }
  };

  const handleDeleteInvitation = async (invitationId) => {
    setIsDeletingInvitation(invitationId);

    try {
      await projectService.deleteProjectMemberInvitation({invitationId});
      setIsDeletingInvitation(null);
      setInvitations(invitations.filter((i) => i.uid !== invitationId));
      toastSuccess('Invitation canceled', {toastId: 'invitation-canceled'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(
        `Deleting project member invitation failed with error`,
        code
      );
      setIsDeletingInvitation(null);
      return toastDanger([title, message], {actions});
    }
  };
  const handleDeleteMember = async (memberId) => {
    setIsDeletingMember(memberId);

    try {
      await projectService.deleteProjectMember({memberId});
      setIsDeletingMember(null);
      setMembers(members.filter((m) => m.uid !== memberId));
      toastSuccess('Member deleted', {toastId: 'member-deleted'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error(`Deleting project member failed with error`, code);
      setIsDeletingMember(null);
      return toastDanger([title, message], {actions});
    }
  };
  const handleUpdateProjectMember = async (memberId, data) => {
    const {role} = data ?? {};

    setIsUpdatingMember(memberId);
    try {
      const projectMemberUpdated = await projectService.updateProjectMember(
        project.uid,
        memberId,
        {
          ...(role.slug != null
            ? {role: role.slug, customRole: null}
            : {role: PROJECT_ROLE_CUSTOM, customRole: role}),
        }
      );

      setMembers(
        members.map((m) =>
          m.uid === projectMemberUpdated.uid ? projectMemberUpdated : m
        )
      );
      setIsUpdatingMember(null);

      toastSuccess('Team member updated!', {toastId: 'member-updated'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating project member failed with error', code);
      toastDanger([title, message], {actions});
      setIsUpdatingMember(null);
    }
  };
  const handleToggleJoin = async () => {
    try {
      const flagsUpdated = reverseFlag(
        F_EXTRA_ALLOW_SAME_DOMAIN_EMAIL_JOIN,
        project.extraFlags
      );

      await projectService.updateProject(project.uid, {
        extraFlags: flagsUpdated,
      });
      uptProject({extraFlags: flagsUpdated});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating allow email domain failed with error', code);
      toastDanger([title, message], {actions});
      setIsUpdatingMember(null);
    }
  };
  const handleInvite = async (e) => {
    e.preventDefault();
    if (reachedProjectSeatsThreshold === true) {
      return updateSubscription({
        planId:
          totalSeats > planStartup.seats && totalSeats < planGrowth.seats
            ? PLAN_GROWTH_ID
            : PLAN_SCALE_ID,
        // title: 'Invite more members',
        description: `You've reached the ${totalSeats} seats limit of your current plan. Upgrade to invite more members.`,
      });
    }
    const customRole = customRoles.find((r) => r.uid === roleToInvite);
    setIsInviting(true);

    try {
      const invitation = await projectService.inviteMember({
        email: inputEmail,
        ...(customRole != null ? {customRole} : {role: roleToInvite}),
      });

      setIsInviting(false);
      setRoleToInvite(PROJECT_ROLE_VIEWER);
      toastSuccess([
        'Invite sent!',
        `Your colleague ${inputEmail} should receive an invitation in a few seconds.`,
      ]);
      setInputEmail('');
      if (
        hasFlag(F_GET_STARTED_INVITE_TEAM, projectMember.getStartedFlags) ===
        false
      ) {
        const newGetStartedFlags = addFlag(
          F_GET_STARTED_INVITE_TEAM,
          projectMember.getStartedFlags
        );

        await projectService.updateGetStartedFlag({
          flag: newGetStartedFlags,
        });
        uptProjectMember({getStartedFlags: newGetStartedFlags});
      }
      setInvitations([...invitations, invitation]);
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      if ([errorCodes.EMAIL_ALREADY_INVITED].includes(code) === false) {
        logger.error('Inviting member failed with error', code);
      }
      toastDanger([title, message], {actions});
      setIsInviting(false);
    }
  };

  invitations.forEach((i) => {
    i.isGettingDeleted = isDeletingInvitation === i.uid ? true : false;
  });
  members.forEach((m) => {
    m.isGettingDeleted = isDeletingMember === m.uid ? true : false;
    m.isGettingUpdated = isUpdatingMember === m.uid ? true : false;
  });
  const isOwner = project.owner?.uid === user.uid;
  const totalSeats = members.length + invitations.length;
  const reachedProjectSeatsThreshold = totalSeats >= project.thresholdSeats;

  return (
    <SettingsBody className="s-settings-team">
      <div className="title-3">Team</div>
      <SettingSubNav
        tabs={[
          {
            tabId: TAB_MEMBERS,
            content: 'Members',
            active: tab === TAB_MEMBERS,
          },
        ].concat(
          hasPermissions(PermissionsSettings.TEAM_UPDATE_ROLE)
            ? {
                tabId: TAB_ROLES,
                content: 'Roles',
                count: builtInRoles.length + customRoles.length,
                active: tab === TAB_ROLES,
              }
            : []
        )}
        onChangeTab={(tab) => setTab(tab)}
      />
      {tab === TAB_MEMBERS ? (
        <>
          {isOwner === true && isCorporateEmail(user.email) === true && (
            <div className="settings-card team-allow-email-domain">
              <div className="content">
                <div className="subtitle-3">Auto-Join via email domain</div>
                <div className="body-3 n-700">
                  Allow <pre>@{user.email.split('@')[1]}</pre> emails to
                  automatically join this project, defaulting to{' '}
                  <pre>Viewer</pre> role.
                </div>
              </div>
              <Toggle
                checked={hasFlag(
                  F_EXTRA_ALLOW_SAME_DOMAIN_EMAIL_JOIN,
                  project.extraFlags
                )}
                onChange={handleToggleJoin}></Toggle>
            </div>
          )}
          <div className="settings-card member-list-wrapper">
            <div className="card-header">
              <div className="card-title subtitle-3">
                Members{' '}
                <span className="n-700" style={{marginLeft: '4px'}}>
                  {members.length + invitations.length}
                </span>
              </div>
              <form onSubmit={handleInvite}>
                <Input
                  type="email"
                  required
                  small
                  disable={reachedProjectSeatsThreshold}
                  iconLeft="isax isax-sms"
                  placeholder={`mate@${user.email.split('@')[1]}`}
                  value={inputEmail}
                  onChange={({target}) => setInputEmail(target.value)}
                  option
                  optionValue={roleToInvite}
                  optionItems={selectRoles}
                  onOptionSelected={(value) => setRoleToInvite(value)}
                />
                <Button
                  thin
                  primary
                  disabled={inputEmail.length === 0}
                  loading={isInviting}>
                  Invite
                </Button>
              </form>
            </div>
            <div className="list-header">
              <span className="body-3 n-500">User</span>
              <span className="body-3 n-500">Status</span>
              <span className="body-3 n-500">Role</span>
            </div>
            <MemberList
              members={members}
              invitations={invitations}
              roles={builtInRoles
                .map((r) => ({
                  ...r,
                  name: getRoleNameBySlug(r.slug),
                }))
                .concat(customRoles)}
              loading={isLoadingMembers}
              onDeleteInvitation={handleDeleteInvitation}
              onDeleteMember={handleDeleteMember}
              onUpdateMember={handleUpdateProjectMember}
            />
          </div>
        </>
      ) : (
        <RolesManager members={members} />
      )}
    </SettingsBody>
  );
};

export default Team;

const MemberList = ({
  members,
  invitations,
  roles,
  loading = false,
  onDeleteInvitation,
  onDeleteMember,
  onUpdateMember,
}) => {
  const classNames = classnames('team-member-list', {
    'is-loading': loading === true,
  });

  if (loading === true) {
    return (
      <div className={classNames}>
        <Loader width="18px" />
        Fetching members
      </div>
    );
  }
  return (
    <div className={classNames}>
      {members
        .map((m) => (
          <Member
            key={m.uid}
            member={m}
            roles={roles}
            onDelete={onDeleteMember}
            onUpdate={onUpdateMember}
          />
        ))
        .concat(
          invitations.map((i) => (
            <Member
              key={i.uid}
              invitation={i}
              onDelete={() => onDeleteInvitation(i.uid)}
            />
          ))
        )}
    </div>
  );
};

const Member = ({member, invitation, roles = [], onDelete, onUpdate}) => {
  const {user, isGettingDeleted, isGettingUpdated} = member ?? {};
  const {isGettingDeleted: inviteIsGettingDeleted} = invitation ?? {};

  const me = generalSelector.getUser();
  const project = generalSelector.getProject();
  const role = roles.find((r) =>
    member.customRole != null
      ? r.uid === member.customRole.uid
      : r.slug === member.role
  );
  const isOwner = user != null && project.owner.uid === user.uid;

  const handleRoleUpdate = (role) => {
    const {value} = role;

    onUpdate(member.uid, {
      role: roles.find((r) => r.slug === value || r.uid === value),
    });
  };

  const selectRoles = roles.map((r) => ({
    label: r.name,
    value: r.slug != null ? r.slug : r.uid,
  }));

  return (
    <div
      className={classnames('member-list-item member-list-item__member', {
        member: user != null,
        invitation: invitation != null,
        'its-you': user != null && me.uid === user.uid,
      })}>
      <div className="member-infos-wrapper">
        <div
          style={
            user != null && user.avatarUrl != null
              ? {backgroundImage: `url(${user.avatarUrl})`}
              : null
          }
          className="member-avatar">
          {user != null && user.avatarUrl == null && (
            <>
              {user != null && user.lastName != null && user.lastName.length > 0
                ? `${user.firstName[0]}${user.lastName[0]}`
                : user.username[0]}
            </>
          )}
          {user == null && invitation.email[0]}
        </div>
        <div className="member-infos">
          {user != null && (
            <div className="member-name">
              {user.lastName != null ? (
                <>
                  {user.firstName} {user.lastName}
                </>
              ) : (
                user.username
              )}
              {isOwner && ' (Owner)'}
            </div>
          )}
          <div className="member-email">
            {user != null ? user.email : invitation.email}
          </div>
        </div>
      </div>
      <div className="member-status">{user != null ? 'active' : 'invited'}</div>
      <div className="actions">
        {user != null && (
          <>
            {me.uid !== user.uid ? (
              <Select
                small
                isDisabled={
                  hasPermissions(
                    PermissionsSettings.TEAM_MEMBER_ROLE_CHANGE
                  ) === false || user.uid === project.owner.uid
                }
                hideDropdown={
                  hasPermissions(
                    PermissionsSettings.TEAM_MEMBER_ROLE_CHANGE
                  ) === false || user.uid === project.owner.uid
                }
                className="select-role"
                isSearchable={false}
                value={selectRoles.find(
                  (r) =>
                    r.value === (role?.slug != null ? role?.slug : role?.uid)
                )}
                options={selectRoles}
                onChange={handleRoleUpdate}
              />
            ) : (
              <Select
                small
                className="select-role my-role"
                isSearchable={false}
                value={selectRoles.find(
                  (r) =>
                    r.value === (role?.slug != null ? role?.slug : role?.uid)
                )}
                options={selectRoles}
                isDisabled
                hideDropdown
              />
            )}
            {me.uid !== user.uid &&
              hasPermissions(PermissionsSettings.TEAM_MEMBER_DELETE) &&
              user.uid !== project.owner.uid && (
                <Button
                  className="btn-remove-member"
                  danger
                  iconOnly
                  loading={isGettingDeleted}
                  onClick={() => onDelete(member.uid)}
                  thin
                  disabled={isGettingUpdated}
                  rounded={false}>
                  <i className="icon-trash"></i>
                </Button>
              )}
          </>
        )}

        {invitation != null &&
          hasPermissions(PermissionsSettings.TEAM_MEMBER_INVITE) && (
            <Button
              className="btn-remove-member"
              danger
              iconOnly
              thin
              onClick={onDelete}
              rounder={false}
              loading={inviteIsGettingDeleted}>
              <i className="icon-trash"></i>
            </Button>
          )}
      </div>
    </div>
  );
};
