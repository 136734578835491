import classnames from 'classnames';
import Button from 'components/Button';
import {ModalConfirm} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {PermissionsPeople} from 'constants/permissions';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {hasPermissions} from 'helpers/permission';
import {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTE_SEGMENTS} from 'router/routes.const';
import {segmentService} from 'services';
import {F_SEGMENT_FROM_DATA_IMPORT} from 'services/segment';
import {Swaler} from 'swaler';
import {SegmentContext} from '../..';
import './_Styles.scss';

const logger = new Swaler('SegmentHeader');

const SegmentHeader = (props) => {
  const {
    segment,
    refetchSegment,
    lookupData,
    name,
    description,
    icon,
    isLegacy,
  } = useContext(SegmentContext);
  const {attributes = [], logic = null} = lookupData || {};
  const {onSave} = props;

  const history = useHistory();

  const [saving, setSaving] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);

  const hasUnsavedChanges = () => {
    return (
      name !== segment.name ||
      description !== segment.description ||
      icon !== segment.icon ||
      (isLegacy !== true &&
        (JSON.stringify(attributes) !== JSON.stringify(segment?.attributes) ||
          JSON.stringify(logic) !== JSON.stringify(segment?.logic)))
    );
  };

  const handleBack = async () => {
    if (hasUnsavedChanges()) {
      setShowExitModal(true);
      return;
    }
    history.push(ROUTE_SEGMENTS);
  };

  const handleSave = async () => {
    setSaving(true);
    if (segment.uid == null) {
      try {
        const createdSegment = await segmentService.createSegment({
          name,
          description,
          icon,
        });

        await segmentService.updateSegmentAttributes(createdSegment.uid, {
          attributes,
          logic,
        });

        toastSuccess('Segment created!', {toastId: 'segment-created'});
        return history.push(ROUTE_SEGMENTS);
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Creating segment failed with error ', code);
        toastDanger([title, message], {actions});
      } finally {
        setSaving(false);
      }

      return;
    }

    try {
      // update logic and attributes
      if (
        isLegacy !== true &&
        hasFlag(F_SEGMENT_FROM_DATA_IMPORT, segment.flags) === false
      ) {
        await segmentService.updateSegmentAttributes(segment.uid, {
          attributes,
          logic,
        });
      }
      await segmentService.updateSegment(segment.uid, {
        name,
        description,
        icon,
      });
      if (hasFlag(F_SEGMENT_FROM_DATA_IMPORT, segment.flags) === false)
        await refetchSegment();
      toastSuccess('Changes saved!', {toastId: 'segment-saved'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Updating segment failed with error ', code);
      toastDanger([title, message], {actions});
    } finally {
      setSaving(false);
    }
  };

  const classNames = classnames('segment-header');

  const canSaveSegment = hasPermissions(PermissionsPeople.SEGMENT_CREATE_EDIT);

  return (
    <div className={classNames}>
      <Button className="back-btn" iconOnly onClick={handleBack}>
        <i className="icon-chevron-left"></i>
      </Button>
      {canSaveSegment && (
        <div className="btns-wrapper">
          <Button
            className="btn-save"
            primary
            reverted
            disabled={hasUnsavedChanges() !== true}
            loading={saving}
            onClick={handleSave}>
            Save
          </Button>
        </div>
      )}

      <ModalConfirm
        className="exit-modal"
        title="Exit without saving?"
        isOpen={showExitModal}
        onConfirm={() => {
          setShowExitModal(false);
          history.push(ROUTE_SEGMENTS);
        }}
        onCancel={() => setShowExitModal(false)}
        cancelText="Cancel"
        confirmText="Exit"
        cancelBtnProps={{
          cta: false,
        }}
        confirmBtnProps={{
          danger: true,
          primary: false,
          cta: false,
        }}>
        <div className="content">
          You have unsaved changes, are you sure you want to exit without
          saving?
        </div>
      </ModalConfirm>
    </div>
  );
};

export default SegmentHeader;
