/* eslint-disable import/first */
import {GoogleOAuthProvider} from '@react-oauth/google';
import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {initStripe} from 'conf/stripe';
import * as dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import posthog from 'posthog-js';
import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import {Provider} from 'react-redux';
import {toast} from 'react-toastify';
import 'reactflow/dist/style.css';
import {PersistGate} from 'redux-persist/integration/react';
import {Swaler, SwalerLevels} from 'swaler';
import App from './App';
import './_Global.scss';
import {Environment} from './conf/env';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configure';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const {store, persistor} = configureStore().create();

Swaler.defaultLevel =
  Environment.NODE_ENV === 'development'
    ? SwalerLevels.TRACE
    : SwalerLevels.INFO;
Sentry.init({
  dsn: Environment.SENTRY_DSN,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  environment: Environment.NODE_ENV,
});
ReactModal.setAppElement('#root');

toast.configure({
  className: 'jimo-toast-container',
  closeButton: false,
  closeOnClick: false,
  hideProgressBar: true,
});

initStripe();

if (Environment.NODE_ENV === 'production') {
  posthog.init('phc_ScFr26ReZODgV1Pp3AEfrilbxoMdE1go1Xy9fP0F38S', {
    api_host: 'https://eu.posthog.com',
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <React.StrictMode>
        <GoogleOAuthProvider clientId={Environment.GOOGLE_OAUTH_CLI}>
          <App />
        </GoogleOAuthProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
