import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {func} from 'prop-types';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {themeService} from 'services';
import './_Styles.scss';
import ModalCreateTheme from './components/ModalCreateTheme';
import {
  applyThemeToEvolution,
  getColorsFromThemeStyle,
  getFontsFromThemeStyle,
  getThemeStyleFromEvolution,
} from './utils';

const propTypes = {
  onClose: func,
};
const defaultProps = {
  onClose: () => {},
};

const ThemeManager = ({onClose}) => {
  const {
    evolution,
    setEvolution,
    controlledEvolution,
    selectedStep: step,
    selectedStepId,
  } = useContext(BuilderContext);

  const [previousPokeStyleSettings, setPreviousPokeStyleSettings] =
    useState(null);
  const [showModalCreateTheme, setShowModalCreateTheme] = useState(false);

  const previousPokeStyleSettingsRef = useRef();

  const {
    data: themes = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: 'themes',
    queryFn: themeService.getThemes,
  });

  useEffect(() => {
    previousPokeStyleSettingsRef.current = previousPokeStyleSettings;
  }, [previousPokeStyleSettings]);

  useEffect(() => {
    return () => {
      if (previousPokeStyleSettingsRef.current != null) {
        setEvolution(previousPokeStyleSettingsRef.current);
      }
    };
  }, []);

  const handleApplyThemeStyle = (theme) => {
    if (previousPokeStyleSettings == null) {
      setPreviousPokeStyleSettings(JSON.parse(JSON.stringify(evolution)));
    }
    const styledEvolution = applyThemeToEvolution(evolution, theme);
    setEvolution(styledEvolution);
  };

  const handleUndoThemeStyle = () => {
    setEvolution({
      ...evolution,
      style: previousPokeStyleSettings.style,
      tourSteps: evolution.tourSteps?.map((ts) => {
        const prevTourStep = previousPokeStyleSettings.tourSteps?.find(
          (t) => t.uid === ts.uid
        );

        ts.style = prevTourStep?.style;
        ts.steps = ts.steps.map((s) => {
          const prevStep = prevTourStep?.steps?.find((ss) => ss.uid === s.uid);

          s.style = prevStep?.style;
          s.blocks = s.blocks.map((b) => {
            const prevBlock = prevStep?.blocks?.find((bb) => bb.uid === b.uid);
            return {
              ...b,
              style: prevBlock?.style,
            };
          });

          return s;
        });

        return ts;
      }),
      steps: evolution.steps?.map((s) => {
        const prevStep = previousPokeStyleSettings.steps?.find(
          (ss) => ss.uid === s.uid
        );

        s.style = prevStep?.style;
        s.blocks = s.blocks.map((b) => {
          const prevBlock = prevStep?.blocks?.find((bb) => bb.uid === b.uid);
          return {
            ...b,
            style: prevBlock?.style,
          };
        });

        s.prototypes = s.prototypes?.map((p) => {
          const prevPrototype = previousPokeStyleSettings.prototypes?.find(
            (pp) => pp.uid === p.uid
          );

          p.steps = p.steps.map((ss) => {
            const prevStep = prevPrototype?.steps?.find(
              (sss) => sss.uid === ss.uid
            );

            ss.blocks = ss.blocks.map((b) => {
              const prevBlock = prevStep?.blocks?.find(
                (bb) => bb.uid === b.uid
              );
              return {
                ...b,
                style: prevBlock?.style,
              };
            });

            return ss;
          });

          return p;
        });

        return s;
      }),
      themeId: previousPokeStyleSettings.themeId,
      theme: previousPokeStyleSettings.theme,
    });
    setPreviousPokeStyleSettings(null);
  };

  return (
    <div className="theme-manager-wrapper">
      <div className="theme-manager">
        <div className="theme-manager-header">
          <div className="theme-manager-title">Theme</div>
        </div>
        <div className="actions">
          <Button
            disabled={selectedStepId == null}
            thin
            onClick={() => setShowModalCreateTheme(true)}>
            Save as new theme
          </Button>
        </div>
        <div className="themes-wrapper">
          {themes
            .filter(
              (theme) =>
                theme.style?.stepStyle != null &&
                theme.style?.blocksStyle != null
            )
            .map((theme) => {
              const {name, style} = theme;
              const colors = getColorsFromThemeStyle(style);

              return (
                <div
                  className={classNames('theme', {
                    selected: theme.uid === evolution.themeId,
                  })}
                  onClick={() => handleApplyThemeStyle(theme)}>
                  <div className="theme-name">{name}</div>
                  <div className="theme-colors">
                    {colors.map((color) => (
                      <div
                        className="theme-color"
                        style={{background: color}}
                      />
                    ))}
                  </div>
                  <div className="theme-font">
                    {getFontsFromThemeStyle(style)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {previousPokeStyleSettings != null ? (
        <div className="theme-manager-footer">
          <div className="theme-manager-footer-title">
            {evolution.theme?.name} theme
          </div>
          <Divider />
          <div className="theme-manager-footer-content">
            You selected the {evolution.theme?.name} theme, would you like to
            apply it to all of the existing & future steps of your experience?
          </div>
          <div className="theme-manager-footer-actions">
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                handleUndoThemeStyle();
              }}>
              Undo
            </Button>
            <Button
              iconLeft="icon-tick"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setPreviousPokeStyleSettings(null);
              }}>
              Apply
            </Button>
          </div>
        </div>
      ) : null}

      {showModalCreateTheme && (
        <ModalCreateTheme
          isOpen={showModalCreateTheme}
          style={getThemeStyleFromEvolution(controlledEvolution, step)}
          onRequestClose={() => {
            setShowModalCreateTheme(false);
          }}
          onThemeCreated={async (theme) => {
            await refetch();
            setShowModalCreateTheme(false);
          }}
        />
      )}
    </div>
  );
};

ThemeManager.propTypes = propTypes;
ThemeManager.defaultProps = defaultProps;

export default ThemeManager;
