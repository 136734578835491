import classNames from 'classnames';
import Divider from 'components/Divider';
import DefaultLoader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import React, {useContext, useEffect, useRef} from 'react';
import {Handle, Position} from 'reactflow';
import {MODE_NAVIGATOR} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {fileService} from 'services';
import {
  F_BOOST_SLOT_TOOLTIP,
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
} from 'services/evolution';
import {BLOCK_TYPE_TARGET} from 'services/steps';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('TargetElement');

const propTypes = {};

const TargetElement = ({evolution, step, steps, isSelected}) => {
  const {
    selectedBlockType,
    setSelectedBlockType,
    setMode,
    setSelectedStepId,
    evolution: parentEvolution,
    selectedStepId,
    setEvolution,
  } = useContext(BuilderContext);
  const {goToEditInApp = () => {}, stopInAppEditing = () => {}} =
    useContext(InAppBuilderContext) || {};

  const parentEvolutionRef = useRef(parentEvolution);
  const evolutionRef = useRef(evolution);

  useEffect(() => {
    parentEvolutionRef.current = parentEvolution;
  }, [parentEvolution]);

  useEffect(() => {
    evolutionRef.current = evolution;
  }, [evolution]);

  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution?.boostFlags);

  const hasProgressOnTargetClick = hasFlag(
    F_OPTION_PROGRESS_ON_TARGET_CLICK,
    evolution?.optionsFlags
  );
  const isLastStep = steps.indexOf(step) === steps.length - 1;

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});

    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file: uploadableFile,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const handleElementSelected = async (data) => {
    setEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: true,
          };
        }
        return tourStep;
      }),
    });

    const file = await uploadFile(data.image);

    setEvolution({
      ...parentEvolutionRef.current,
      tourSteps: parentEvolutionRef.current.tourSteps.map((tourStep) => {
        if (tourStep.uid === evolutionRef.current?.uid) {
          return {
            ...tourStep,
            ...data.evolution,
            isUploading: false,
            ...(file != null
              ? {
                  file,
                }
              : {
                  file: null,
                }),
          };
        }
        return tourStep;
      }),
    });
  };

  const handleGoToEditInApp = () => {
    goToEditInApp({
      data: {
        title: 'Select element',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'ELEMENT_SELECTOR_BUILDER',
          evolution: parentEvolutionRef.current,
          stepId: step.uid,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'TARGET_ELEMENT_SELECTED') {
          handleElementSelected(message.data);
          stopInAppEditing();
        }
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  return (
    <div
      className={classNames('step-node-selector-preview-wrapper', {
        selected: isSelected && selectedBlockType === BLOCK_TYPE_TARGET,
      })}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setSelectedStepId(step.uid);
        setMode(MODE_NAVIGATOR);
        setSelectedBlockType(BLOCK_TYPE_TARGET);

        if (
          !evolution.boostedQueryselector &&
          evolution.querySelectorManual == null
        ) {
          handleGoToEditInApp();
        }
      }}
      onMouseDownCapture={(e) => {
        e.stopPropagation();
      }}>
      {evolution.boostedQueryselector ||
      evolution.querySelectorManual != null ? (
        <>
          {evolution.file?.publicUrl != null ? (
            <img src={evolution.file?.publicUrl} alt="element" />
          ) : (
            <div className="image-not-found">
              <i className="icon-image" />
              No preview found
            </div>
          )}
        </>
      ) : (
        <div className="pinned-element-empty-state">
          <i className="isax isax-gps" />
          <Divider vertical dark />
          <div className="content">Target an element in-app</div>
        </div>
      )}

      {evolution.isUploading === true && (
        <div className="uploading-overlay">
          <DefaultLoader width={24} />
        </div>
      )}

      {isTooltip === true &&
        hasProgressOnTargetClick === true &&
        isLastStep !== true && (
          <Handle
            type="source"
            position={Position.Right}
            id={`${step.uid}-target`}
            isConnectable={false}
            isConnectableStart={false}
          />
        )}

      <ClickableBlockOverlay isNotCardElem />
    </div>
  );
};

TargetElement.propTypes = propTypes;

export default TargetElement;
