import Divider from 'components/Divider';
import InputGroup from 'components/Input';
import SelectGroup from 'components/Select';
import {BuilderContext} from 'contextes/builder';
import {useContext, useEffect, useRef, useState} from 'react';
import {DropdownAddAction} from 'scenes/PokeBuilder/components/TriggerManager/components/Triggers/components/TriggerActions';
import {
  EVOLUTION_TYPE_BANNER,
  EVOLUTION_TYPE_HINT,
  EVOLUTION_TYPE_SURVEY,
  EVOLUTION_TYPE_TOUR,
} from 'services/evolution';
import {
  BLOCK_TYPE_SECONDARY_CTA,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_DISMISS,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
  STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
  STEP_CONDITION_ACTION_TYPE_OPEN_POST,
  STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
  STEP_CONDITION_ACTION_TYPE_SNOOZE,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import RadioGroup from '../../../../../../components/RadioGroup';
import ClickableInput from '../../components/items/ClickableInput';
import ColorPickerInput from '../../components/items/ColorPickerInput';
import PixelPicker from '../../components/items/PixelPicker';
import {fontWeightOptions} from '../Title';
import {fontFamiliesOptions} from '../Title/utils';
import './_Styles.scss';
import ButtonAction from './components/ButtonAction';

const alignOptions = [
  {label: <i className="icon-align-b-l" />, value: 'left'},
  {label: <i className="icon-align-b-c" />, value: 'center'},
  {label: <i className="icon-align-b-r" />, value: 'right'},
];

const Button = () => {
  const {
    evolution,
    selectedStep: step,
    updateBlock: uptBlock,
    selectedBlockType,
    selectedStep,
    updateStep,
  } = useContext(BuilderContext);

  const isTour = evolution?.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;
  const isBanner = evolution?.type === EVOLUTION_TYPE_BANNER;
  const isHint = evolution?.type === EVOLUTION_TYPE_HINT;

  const isSecondaryCta = selectedBlockType === BLOCK_TYPE_SECONDARY_CTA;

  const [newlyAddedAction, setNewlyAddedAction] = useState(null);

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 300); // delay focus input of 300ms to match the animation and prevent whole page from scrolling
    return () => clearTimeout(timeout);
  }, []);

  const updateBlock = (updateObj) => {
    uptBlock(selectedBlockType, updateObj);
  };

  const handleAddAction = (type, opts = {}) => {
    const newAction = {
      ...opts,
      uid: uuidv4(),
      type,
    };

    updateBlock({
      actions: [...actions, newAction],
    });
    setNewlyAddedAction(newAction);
  };

  const block = step?.blocks.find((b) => b.type === selectedBlockType);
  const steps = isSurvey
    ? evolution.steps.filter((s) => s.removed !== true)
    : evolution.tourSteps
        .map((ts) =>
          ts.steps.map((s) => ({
            ...s,
            tourStepIndex: ts.tourStepInfo.split(';')[0],
          }))
        )
        .flat()
        .filter((s) => s.removed !== true);

  steps.sort(
    (
      {tourStepIndex: tourStepIndexA, indexOrder: indexOrderA},
      {tourStepIndex: tourStepIndexB, indexOrder: indexOrderB}
    ) => {
      // first sort on tourStepIndex and then on indexOrder
      if (tourStepIndexA < tourStepIndexB) {
        return -1;
      }
      if (tourStepIndexA > tourStepIndexB) {
        return 1;
      }
      if (indexOrderA < indexOrderB) {
        return -1;
      }
      if (indexOrderA > indexOrderB) {
        return 1;
      }
      return 0;
    }
  );
  const currentStepIndex = steps.findIndex((s) => s.uid === step.uid);

  if (block == null) {
    return <></>;
  }

  const {value, style, actions = []} = block;

  const isSnoozeAuthorized =
    (isTour || isSurvey) && currentStepIndex === 0 && isSecondaryCta;
  const hasSnoozeHint =
    (isTour || isSurvey) && isSecondaryCta && currentStepIndex !== 0; // everything BUT step is correct

  const [text, action] = value.split(';');

  const disabledActions = [
    ...(hasSnoozeHint === true ? [STEP_CONDITION_ACTION_TYPE_SNOOZE] : []),
  ];

  let authorizedActions = [
    STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
    STEP_CONDITION_ACTION_TYPE_DISMISS,
    STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
    STEP_CONDITION_ACTION_TYPE_OPEN_POST,
    STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO,
    STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE,
    ...(isSnoozeAuthorized ? [STEP_CONDITION_ACTION_TYPE_SNOOZE] : []),
  ].filter((a) => actions.map((action) => action.type).includes(a) !== true);

  if (isBanner || isHint) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }

  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(t)
      ) === true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE,
          STEP_CONDITION_ACTION_TYPE_OPEN_POST,
          STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }
  if (
    actions
      .map((action) => action.type)
      .some((t) => [STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW].includes(t)) ===
    true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) => a !== STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO
    );
  }
  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(t)
      ) === true
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }

  if (
    actions
      .map((action) => action.type)
      .some((t) =>
        [
          STEP_CONDITION_ACTION_TYPE_DISMISS,
          STEP_CONDITION_ACTION_TYPE_SNOOZE,
        ].includes(t)
      )
  ) {
    authorizedActions = authorizedActions.filter(
      (a) =>
        [
          STEP_CONDITION_ACTION_TYPE_SNOOZE,
          STEP_CONDITION_ACTION_TYPE_DISMISS,
        ].includes(a) !== true
    );
  }

  const updateSharedAction = (updatedAction) => {
    updateStep(selectedStep.uid, {
      blocks: selectedStep?.blocks?.map((block) => ({
        ...block,
        actions: block?.actions?.map((_action) =>
          _action.type === updatedAction.type
            ? {..._action, value: updatedAction.value}
            : _action
        ),
      })),
      triggers: selectedStep?.triggers?.map((t) => ({
        ...t,
        actions: t.actions.map((ta) =>
          ta.type === updatedAction.type
            ? {...ta, value: updatedAction.value}
            : ta
        ),
      })),
    });
  };

  return (
    <div className="block-settings button">
      <div className="section">
        <div className="section-title">Behavior</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Actions</div>
            <div className="section-item-content action-picker">
              <div className="actions-wrapper">
                {actions.map((action) => {
                  return (
                    <ButtonAction
                      key={action.uid}
                      action={action}
                      setAction={(updatedAction) => {
                        if (
                          updatedAction.type ===
                          STEP_CONDITION_ACTION_TYPE_SNOOZE
                        ) {
                          updateSharedAction(updatedAction);
                        } else {
                          updateBlock({
                            actions: actions.map((_action) =>
                              _action.uid === action.uid
                                ? updatedAction
                                : _action
                            ),
                          });
                        }
                      }}
                      onDelete={() => {
                        updateBlock({
                          actions: actions.filter(
                            (_action) => _action.uid !== action.uid
                          ),
                        });
                      }}
                      defaultOpen={newlyAddedAction?.uid === action.uid}
                    />
                  );
                })}
                <DropdownAddAction
                  className="add-action-element-button"
                  authorizedActions={authorizedActions}
                  disabledActions={disabledActions}
                  usedActionTypes={actions.map((action) => action.type)}
                  onAddAction={handleAddAction}
                  position="left top"
                  offsetY={-4}
                  offsetX={8}
                  trigger={
                    <ClickableInput
                      className="add-action-element-placeholder-btn"
                      inputProps={{placeholder: 'Add action...'}}
                      leftLabel={
                        <div className="action-icon-wrapper">
                          <i className="isax isax-flash-15" />
                        </div>
                      }
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="section">
        <div className="section-title">Layout</div>
        <div className="section-content">
          <div className="section-item">
            <div className="section-item-title">Text</div>
            <div className="section-item-content">
              <InputGroup
                className="button-content-input"
                value={text}
                onChange={({target}) =>
                  updateBlock({
                    value: `${target.value};${action}`,
                  })
                }
                ref={inputRef}
              />
            </div>
          </div>
          <ButtonStyle
            style={style}
            updateStyle={updateBlock}
            hideAlign={isBanner}
          />
        </div>
      </div>
    </div>
  );
};

export const ButtonStyle = ({style, updateStyle, hideAlign = false}) => {
  return (
    <>
      <div className="section-item">
        <div className="section-item-title body-3">Font family</div>
        <div className="section-item-content">
          <SelectGroup
            small
            options={fontFamiliesOptions}
            value={fontFamiliesOptions.find(
              (o) => o.value === style.fontFamily
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontFamily: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font-size</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.fontSize}
            min={8}
            max={42}
            onChange={(value) => {
              updateStyle({
                style: {...style, fontSize: value},
              });
            }}
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Font weight</div>
        <div className="section-item-content">
          <SelectGroup
            isSearchable={false}
            small
            options={fontWeightOptions}
            value={fontWeightOptions.find(
              (o) => o.value === (style.fontWeight || '400')
            )}
            onChange={(option) =>
              updateStyle({
                style: {...style, fontWeight: option.value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Padding</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.padding}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, padding: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Radius</div>
        <div className="section-item-content">
          <PixelPicker
            small
            value={style.borderRadius}
            min={0}
            max={24}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderRadius: value},
              })
            }
            radius
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Text color</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Text color"
            value={style.fontColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, fontColor: value},
              })
            }
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Background</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title={'Background'}
            value={style.primaryColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, primaryColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      <div className="section-item">
        <div className="section-item-title body-3">Border</div>
        <div className="section-item-content">
          <ColorPickerInput
            inputProps={{small: true}}
            title="Border color"
            value={style.borderColor}
            onChange={(value) =>
              updateStyle({
                style: {...style, borderColor: value},
              })
            }
            erasable
          />
        </div>
      </div>
      {hideAlign !== true && (
        <div className="section-item">
          <div className="section-item-title body-3">Position</div>
          <div className="section-item-content">
            <RadioGroup
              value={style.align}
              options={alignOptions}
              onSelect={(value) =>
                updateStyle({
                  style: {
                    ...style,
                    align: value,
                  },
                })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Button;
