import {default as classNames, default as classnames} from 'classnames';
import Button from 'components/Button';
import {BuilderContext} from 'contextes/builder';
import {BLOCKS} from 'helpers/poke';
import {getStepIssue} from 'helpers/step';
import {bool, object} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
  BLOCK_TYPE_ANIMATION,
  BLOCK_TYPE_BODY,
  BLOCK_TYPE_CHOICE,
  BLOCK_TYPE_CONCEPT,
  BLOCK_TYPE_NPS,
  BLOCK_TYPE_OPEN_QUESTION,
  BLOCK_TYPE_OPINION,
  BLOCK_TYPE_PRIMARY_CTA,
  BLOCK_TYPE_SECONDARY_CTA,
  BLOCK_TYPE_SLIDER,
  BLOCK_TYPE_STEPPER,
  BLOCK_TYPE_TITLE,
  BLOCK_TYPE_USER,
  STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
  STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_SUCCESS,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
  getDefaultStep,
} from 'services/steps';
import {v4 as uuidv4} from 'uuid';
import {getDefaultBlockFromType} from '../BlockManager/utils';
import {DropdownAddBlock} from '../DropdownAddBlock';
import RemoveStepModal from '../RemoveStepModal';
import StepPreview from '../StepPreview';
import './_Styles.scss';
import {getNewStepName} from './utils';

const propTypes = {
  inDalaran: bool,
  stepEditorRef: object,
  draggedOffset: object,
};

const defaultProps = {
  inDalaran: false,
  stepEditorRef: null,
  draggedOffset: null,
};

const DiscoveryStepsManager = ({inDalaran, stepEditorRef, draggedOffset}) => {
  const {
    evolution,
    setEvolution,
    selectedStepId,
    setSelectedStepId,
    messenger,
    isInApp,
    setInAppForegroundForce,
    setInConcept,
    setSelectedBlockType,
    originalEvolution,
  } = useContext(BuilderContext);

  const [stepIdToDelete, setStepIdToDelete] = useState(null);
  const [draggedStepId, setDraggedStepId] = useState(null);
  const [addBlockOpen, setAddBlockOpen] = useState(false);

  useEffect(() => {
    if (selectedStepId == null) {
      setSelectedStepId(evolution.steps[0]?.uid);
    }
  }, []);

  // For in-app builder, we need to bring it to the foreground to click on the confirm delete modale
  useEffect(() => {
    if (isInApp === false) {
      return;
    }
    setInAppForegroundForce(stepIdToDelete != null);
  }, [stepIdToDelete]);
  // For in-app builder, we need to bring it to the foreground to prevent dropdown to be under poke
  useEffect(() => {
    if (isInApp === false) {
      return;
    }
    setInAppForegroundForce(addBlockOpen);
  }, [addBlockOpen]);

  const isStepLive =
    evolution.isDraft !== true &&
    originalEvolution.steps?.some(
      (s) =>
        s.uid === stepIdToDelete ||
        s.prototypes?.[0]?.steps?.some((pS) => pS.uid === stepIdToDelete)
    );

  const handleDeleteStep = (stepId) => {
    const step = evolution.steps.find((s) => s.uid === stepId);
    const isPrototypeStep = step != null && step.prototypes.length > 0;
    const selectedStepIsInPrototype =
      isPrototypeStep === true
        ? step.prototypes[0].steps.find((s) => s.uid === selectedStepId) != null
        : false;

    if (isStepLive) {
      setEvolution({
        ...evolution,
        steps: evolution.steps.map((s) => {
          if (s.uid === stepId) {
            return {
              ...s,
              removed: true,
            };
          }
          if (s.prototypes?.[0]?.steps?.some((pS) => pS.uid === stepId)) {
            return {
              ...s,
              prototypes: s.prototypes.map((p) => {
                if (p.steps.some((pS) => pS.uid === stepId)) {
                  return {
                    ...p,
                    steps: p.steps.map((pS) => {
                      if (pS.uid === stepId) {
                        return {
                          ...pS,
                          removed: true,
                        };
                      }
                      return pS;
                    }),
                  };
                }
                return p;
              }),
            };
          }

          return s;
        }),
      });
    } else {
      if (
        selectedStepId === stepId ||
        (isPrototypeStep === true && selectedStepIsInPrototype === true)
      ) {
        const index = evolution.steps.map((s) => s.uid).indexOf(stepId);
        if (index > 0) {
          setSelectedStepId(evolution.steps[index - 1].uid);
        } else if (evolution.steps.length <= 1) {
          setAddBlockOpen(true);
          setSelectedBlockType(null);
        } else {
          setSelectedStepId(evolution.steps[index + 1]?.uid);
        }
        if (isPrototypeStep === true && selectedStepIsInPrototype === true) {
          setInConcept(null);
        }
      }
      setSelectedBlockType(null);

      const updatedSteps = Array.from(evolution.steps).filter(
        (s) => s.uid !== stepId
      );
      updatedSteps.sort((a, b) => a.indexOrder - b.indexOrder);
      updatedSteps.forEach((s, index) => {
        s.indexOrder = index;
        s.jumps = s.jumps?.filter((j) => j.goTo !== stepId) || [];
      });

      setEvolution({
        ...evolution,
        steps: updatedSteps,
      });
    }
    setStepIdToDelete(null);
  };

  const unhideStep = (stepId) => {
    setEvolution({
      ...evolution,
      steps: evolution.steps.map((s) => {
        if (s.uid === stepId) {
          return {
            ...s,
            removed: false,
          };
        }
        if (s.prototypes?.[0]?.steps?.some((pS) => pS.uid === stepId)) {
          return {
            ...s,
            prototypes: s.prototypes.map((p) => {
              if (p.steps.some((pS) => pS.uid === stepId)) {
                return {
                  ...p,
                  steps: p.steps.map((pS) => {
                    if (pS.uid === stepId) {
                      return {
                        ...pS,
                        removed: false,
                      };
                    }
                    return pS;
                  }),
                };
              }
              return p;
            }),
          };
        }

        return s;
      }),
    });
  };

  const onDragStart = ({draggableId}) => {
    setDraggedStepId(draggableId);
  };
  const onDragEnd = async (result) => {
    setDraggedStepId(null);

    if (!result.destination) {
      return;
    }

    const {
      type,
      source: {index: sourceIndex, droppableId: sourceDroppableId},
      destination: {index: destinationIndex},
    } = result;

    if (type === 'steps') {
      const reorderedSteps = Array.from(evolution.steps);
      const [removed] = reorderedSteps.splice(sourceIndex, 1);
      reorderedSteps.splice(destinationIndex, 0, removed);
      reorderedSteps.forEach((s, index) => {
        s.indexOrder = index;
        if (s.uid === removed.uid) {
          s.reordered = true;
        }
      });

      setEvolution({...evolution, steps: reorderedSteps});
    } else if (type === 'concept-test-steps') {
      setEvolution({
        ...evolution,
        steps: evolution.steps.map((step) => {
          if (step.uid === sourceDroppableId) {
            const [removed] = step.prototypes[0].steps.splice(sourceIndex, 1);
            step.prototypes[0].steps.splice(destinationIndex, 0, removed);
            step.prototypes[0].steps.forEach((s, index) => {
              s.indexOrder = index;
              if (s.uid === removed.uid) {
                s.reordered = true;
              }
            });
          }
          return step;
        }),
      });
    }
  };

  const addStep = (type, fromPrototypeData) => {
    const profileBlockInFirstStep = evolution.steps?.[0]?.blocks?.find(
      (b) => b.type === BLOCK_TYPE_USER
    );
    const stepperBlockInExistingSteps = evolution.steps
      ?.map((s) => s.blocks)
      ?.flat()
      ?.find((b) => b.type === BLOCK_TYPE_STEPPER);

    const step = getDefaultStep({
      name: getNewStepName(type, evolution.steps),
      type,
      blocks: [
        {
          ...getDefaultBlockFromType(BLOCK_TYPE_TITLE, evolution.theme),
          ...(type === STEP_TYPE_SUCCESS
            ? {value: 'Thank you for your input!|-|none|-|'}
            : {}),
        },

        ...(type === STEP_TYPE_TEXT_BLOCK
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : []),
        ...(type === STEP_TYPE_SUCCESS
          ? [
              {
                ...getDefaultBlockFromType(BLOCK_TYPE_BODY, evolution.theme),
                value:
                  'You just completed our survey. Thank you a lot for helping us improve our product.',
                rawValue: null,
              },
            ]
          : []),
        ...(type === STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_CHOICE, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : type === STEP_TYPE_SLIDER
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_SLIDER, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : type === STEP_TYPE_NPS
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_NPS, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : type === STEP_TYPE_OPINION_SCALE
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_OPINION, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : type === STEP_TYPE_TEXT_LONG
          ? [
              getDefaultBlockFromType(
                BLOCK_TYPE_OPEN_QUESTION,
                evolution.theme
              ),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : type === STEP_TYPE_SUCCESS
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_ANIMATION, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : type === STEP_TYPE_INTERVIEW
          ? [
              {
                ...getDefaultBlockFromType(
                  BLOCK_TYPE_PRIMARY_CTA,
                  evolution.theme
                ),
                value: 'Book a call;step-next;open-booking-url;',
                actions: [
                  {
                    uid: uuidv4(),
                    type: STEP_CONDITION_ACTION_TYPE_GO_TO_STEP,
                    value: 'next-step',
                  },
                  {
                    uid: uuidv4(),
                    type: STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW,
                  },
                ],
              },
              {
                ...getDefaultBlockFromType(
                  BLOCK_TYPE_SECONDARY_CTA,
                  evolution.theme
                ),
                value: 'Not now;step-next;none;',
              },
            ]
          : type === STEP_TYPE_CONCEPT_TEST
          ? [
              getDefaultBlockFromType(BLOCK_TYPE_CONCEPT, evolution.theme),
              getDefaultBlockFromType(BLOCK_TYPE_PRIMARY_CTA, evolution.theme),
            ]
          : []),
        ...(profileBlockInFirstStep != null
          ? [{...profileBlockInFirstStep, uid: uuidv4()}]
          : []),
        ...(stepperBlockInExistingSteps != null
          ? [{...stepperBlockInExistingSteps, uid: uuidv4()}]
          : []),
      ],
    });

    if (fromPrototypeData == null) {
      setEvolution({
        ...evolution,
        steps: [
          ...evolution.steps,
          {
            ...step,
            indexOrder: evolution.steps.length,
          },
        ],
      });
      setSelectedStepId(step.uid);
      setInConcept(null);
    } else {
      const updatedSteps = evolution.steps.map((s) =>
        s.uid === fromPrototypeData.stepId
          ? {
              ...s,
              prototypes: s.prototypes.map((p) =>
                p.uid === fromPrototypeData.prototypeId
                  ? {
                      ...p,
                      steps: p.steps.concat({
                        ...step,
                        indexOrder: p.steps.length,
                      }),
                    }
                  : p
              ),
            }
          : s
      );

      setInConcept(`${fromPrototypeData.stepId}|${step.uid}`);
      setSelectedStepId(step.uid);
      setEvolution({
        ...evolution,
        steps: updatedSteps,
      });
    }
    setSelectedBlockType(null);
    messenger?.sendInAppForegroundForce(false);
  };

  const {steps = []} = evolution;

  return (
    <div className="discovery-steps-manager">
      <div className={classNames('step-block-list')}>
        {steps.length > 0 && (
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            style={{overflow: 'auto'}}>
            <Droppable droppableId="steps" type="steps" direction="horizontal">
              {(dropProvided) => (
                <>
                  <div
                    ref={dropProvided.innerRef}
                    className="droppable-block-list"
                    {...dropProvided.droppableProps}>
                    {steps
                      .filter((step) => step.removed !== true)
                      .sort((a, b) => a.indexOrder - b.indexOrder)
                      .map((step, index) => (
                        <StepButton
                          step={step}
                          index={index}
                          stepEditorRef={stepEditorRef}
                          onStepIdToDelete={setStepIdToDelete}
                          onAddStep={addStep}
                          inDalaran={inDalaran}
                          draggedOffset={draggedOffset}
                          onUnhideStep={unhideStep}
                        />
                      ))}
                  </div>
                  {dropProvided.placeholder}
                </>
              )}
            </Droppable>
          </DragDropContext>
        )}

        <DropdownAddBlock
          open={addBlockOpen || !(evolution.steps.length > 0)}
          onClose={() => setAddBlockOpen(false)}
          dropdownProps={{
            position: 'bottom center',
            trigger: (
              <div className="icon-wrapper">
                <i className="icon-plus" />
              </div>
            ),
            triggerClassName: 'list-dropdown-trigger',
          }}
          onAdd={addStep}
        />
      </div>

      {stepIdToDelete != null && (
        <RemoveStepModal
          isOpen={stepIdToDelete != null}
          onRequestClose={() => setStepIdToDelete(null)}
          onConfirm={() => handleDeleteStep(stepIdToDelete)}
          isLiveStep={isStepLive}
        />
      )}
    </div>
  );
};

const StepButton = ({
  step,
  index,
  stepEditorRef,
  draggedOffset,
  inDalaran,
  onStepIdToDelete,
  onUnhideStep,
  onAddStep,
}) => {
  const {
    evolution,
    selectedStepId,
    setSelectedStepId,
    setInConcept,
    setSelectedBlockType,
  } = useContext(BuilderContext);

  const hasNoPrevStep = index === 0;
  const issue = getStepIssue(step, {hasNoPrevStep});
  const blockType = BLOCKS.find((b) => b.step === step.type);

  return (
    <Draggable key={step.uid} draggableId={step.uid} index={index}>
      {(dragProvided, snapshot) => {
        if (snapshot.isDragging && stepEditorRef != null) {
          const rect = stepEditorRef.current?.getBoundingClientRect();
          const offset = {
            x: stepEditorRef.current?.offsetLeft + draggedOffset.x,
            y: stepEditorRef.current?.offsetTop + draggedOffset.y,
          }; // your fixed container left/top position
          if (inDalaran === true) {
            offset.x = offset.x + rect?.x;
            offset.y = offset.y + rect?.y;
          }

          const x = dragProvided.draggableProps.style.left - offset.x;
          const y = dragProvided.draggableProps.style.top - offset.y;
          dragProvided.draggableProps.style.left = x;
          dragProvided.draggableProps.style.top = y;
        }

        return (
          <>
            <div
              className={classNames('block-wrapper', {
                'is-dragging': snapshot.isDragging,
                'is-step-concept': step.type === STEP_TYPE_CONCEPT_TEST,
                'is-sub-concept-step-selected':
                  step.prototypes?.[0]?.steps.findIndex(
                    (s) => s.uid === selectedStepId
                  ) > -1,
                'is-parent-concept-step-selected':
                  selectedStepId === step.uid &&
                  step.prototypes?.[0]?.steps.length > 0,
              })}
              ref={dragProvided.innerRef}
              {...dragProvided.draggableProps}
              {...dragProvided.dragHandleProps}>
              <div
                className={classNames('block-selector', {
                  selected: step.uid === selectedStepId,
                  invalid: selectedStepId !== step.uid && issue != null,
                  'is-dragging': snapshot.isDragging,
                  removed: step.removed === true,
                })}
                style={{
                  background: blockType?.iconBackgroundColor,
                  border: `2px solid ${blockType?.iconBackgroundColor}`,
                }}
                onClick={() => {
                  setInConcept(null);
                  setSelectedStepId(step.uid);
                  setSelectedBlockType(null);
                }}>
                <div className="block-type-icon">{blockType?.icon}</div>
                {step.removed === true ? (
                  <Button
                    className="unhide-btn"
                    iconLeft="icon-eye"
                    iconOnly
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      onUnhideStep(step.uid);
                    }}
                  />
                ) : (
                  <Button
                    className="delete-btn"
                    iconLeft="icon-close"
                    iconOnly
                    danger
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      onStepIdToDelete(step.uid);
                    }}
                  />
                )}

                <div className="step-preview-wrapper">
                  <StepPreview
                    icon={blockType?.icon}
                    title={step.name}
                    subtitle={blockType?.title}
                    poke={evolution}
                    selectedStepId={step.uid}
                  />
                </div>
              </div>
              {step.type === STEP_TYPE_CONCEPT_TEST &&
                step.prototypes?.[0]?.steps?.length > 0 && (
                  <Droppable
                    droppableId={step.uid}
                    type="concept-test-steps"
                    isDropDisabled={false}
                    direction="horizontal">
                    {(dropProvidedStep) => (
                      <div
                        className={classnames('sub-blocks-wrapper')}
                        style={{
                          display: 'flex',
                        }}
                        ref={dropProvidedStep.innerRef}>
                        {step.prototypes?.[0]?.steps
                          ?.filter((s) => s.removed !== true)
                          ?.sort((a, b) => a.indexOrder - b.indexOrder)
                          ?.map((prototypeStep, prototypeStepIndex) => (
                            <SubStepButton
                              step={step}
                              prototypeStep={prototypeStep}
                              prototypeStepIndex={prototypeStepIndex}
                              onUnhideStep={onUnhideStep}
                            />
                          ))}
                        {dropProvidedStep.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
            </div>
            {dragProvided.placeholder}
          </>
        );
      }}
    </Draggable>
  );
};

const SubStepButton = ({
  prototypeStep,
  step,
  prototypeStepIndex,
  onUnhideStep,
}) => {
  const {
    evolution,
    setEvolution,
    selectedStepId,
    setSelectedStepId,
    setInConcept,
    setSelectedBlockType,
  } = useContext(BuilderContext);

  const issue = getStepIssue(prototypeStep);
  const blockType = BLOCKS.find((b) => b.step === prototypeStep.type);

  return (
    <Draggable
      key={prototypeStep.uid}
      draggableId={prototypeStep.uid}
      index={prototypeStepIndex}
      isDragDisabled={false}>
      {(dragProvidedStep, dragSnapshotStep) => {
        return (
          <div
            className="sub-block-selector-wrapper"
            ref={dragProvidedStep.innerRef}
            {...dragProvidedStep.draggableProps}
            {...dragProvidedStep.dragHandleProps}>
            <div
              className={classNames('sub-block-selector', {
                selected: prototypeStep.uid === selectedStepId,
                invalid: selectedStepId !== prototypeStep.uid && issue != null,
                'is-dragging': dragSnapshotStep.isDragging,
                removed: prototypeStep.removed === true,
              })}
              style={{
                background: blockType?.iconBackgroundColor,
                border: `2px solid ${blockType?.iconBackgroundColor}`,
              }}
              onClick={() => {
                setInConcept(`${step.uid}|${prototypeStep.uid}`);
                setSelectedStepId(prototypeStep.uid);
              }}>
              <div className="block-type-icon">{blockType?.icon}</div>
              {step.removed === true ? (
                <Button
                  className="unhide-btn"
                  iconLeft="icon-eye"
                  iconOnly
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    onUnhideStep(prototypeStep.uid);
                  }}
                />
              ) : (
                <Button
                  className="delete-btn"
                  iconLeft="icon-close"
                  iconOnly
                  danger
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (selectedStepId === prototypeStep.uid) {
                      const prototypeSteps = step.prototypes[0]?.steps;
                      const index = prototypeSteps
                        .map((s) => s.uid)
                        .indexOf(prototypeStep.uid);
                      if (index > 0) {
                        setSelectedStepId(prototypeSteps[index - 1].uid);
                      } else if (prototypeSteps.length <= 1) {
                      } else {
                        setSelectedStepId(prototypeSteps[index + 1]?.uid);
                      }
                      setSelectedBlockType(null);
                    }
                    setEvolution({
                      ...evolution,
                      steps: evolution.steps.map((s) => {
                        if (
                          s.prototypes?.[0]?.steps
                            ?.map((pS) => pS.uid)
                            ?.includes(prototypeStep.uid)
                        ) {
                          const updatedSteps = Array.from(
                            s.prototypes[0].steps
                          ).filter((pS) => pS.uid !== prototypeStep.uid);
                          updatedSteps.sort(
                            (a, b) => a.indexOrder - b.indexOrder
                          );
                          updatedSteps.forEach((s, index) => {
                            s.indexOrder = index;
                          });

                          s.prototypes[0].steps = updatedSteps;
                        }
                        return s;
                      }),
                    });
                  }}
                />
              )}
            </div>
            {dragProvidedStep.placeholder}
          </div>
        );
      }}
    </Draggable>
  );
};

DiscoveryStepsManager.propTypes = propTypes;
DiscoveryStepsManager.defaultProps = defaultProps;

export default DiscoveryStepsManager;
