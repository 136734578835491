import classnames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {GlobalContext} from 'contextes/Global';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useState} from 'react';
import {STEP_TYPE_CHECKLIST} from 'services/steps';
import {Poke} from 'shared/front/components/Poke';
import {F_SLOT_TOP_BAR} from 'shared/front/components/Poke/constants/poke';
import {PokeContext} from 'shared/front/components/Poke/context';
import {BlockGradient} from '../PokeRender/components/BlockGradient';
import {getPokeWrapperStyle} from '../PokeRender/utils';
import './_styles.scss';

const StepPreview = ({icon, title, subtitle, poke, selectedStepId}) => {
  const {evolution} = useContext(BuilderContext);
  const {addFontFamily} = useContext(GlobalContext);

  const [style, setStyle] = useState({});

  const currentStepIndex = poke.steps.findIndex(
    (s) => s.uid === selectedStepId
  );
  const step = poke.steps[currentStepIndex];
  const isChecklistStep = step?.type === STEP_TYPE_CHECKLIST;

  const isHeightAuto = poke.style?.height == null;

  const pokeWrapperStyle = getPokeWrapperStyle(poke?.style, isChecklistStep);

  const [indexTourStep] = poke.tourStepInfo?.split(';') ?? [0];

  const {stepsBefore, stepsAfter} = evolution?.tourSteps?.reduce(
    (acc, cur) => {
      if (cur.uid === poke.uid) {
        return acc;
      }

      const [index] = cur.tourStepInfo?.split(';');
      const indexToInt = parseInt(index, 10);

      if (indexToInt < indexTourStep) {
        acc.stepsBefore =
          acc.stepsBefore + cur.steps.filter((s) => s.removed !== true).length;
      } else if (indexToInt > indexTourStep) {
        acc.stepsAfter =
          acc.stepsAfter + cur.steps.filter((s) => s.removed !== true).length;
      }
      return acc;
    },
    {stepsBefore: 0, stepsAfter: 0}
  );

  return (
    <PokeContext.Provider
      value={{
        poke: {
          ...poke,
          steps: poke?.steps?.sort((a, b) => a.indexOrder - b.indexOrder),
          stepsBefore,
          stepsAfter,
        },
        currentStepIndex,
      }}>
      <div className="step-preview-content-wrapper">
        <div className="step-preview-info">
          <div className="icon">{icon}</div>
          <div className="content">
            <div className="title subtitle-4 n-800">{title}</div>
            <div className="subtitle body-4 n-500">{subtitle}</div>
          </div>
        </div>
        <div className="step-preview-content">
          <div
            className={classnames('step-preview-poke-render', {
              'auto-height': isHeightAuto,
            })}
            style={{
              ...style,
              boxShadow: '0px 9px 16px rgb(12 23 37 / 16%)',
              width:
                hasFlag(F_SLOT_TOP_BAR, poke.boostFlags) === true
                  ? '100%'
                  : 'auto',
              ...(isHeightAuto !== true ? {height: 'auto'} : {}),
              ...pokeWrapperStyle,
            }}>
            <Poke
              onDimensionChange={() => {}}
              onContainerStyleChange={(style) => {
                setStyle(style);
              }}
              disableAnimations
              addFontFamily={addFontFamily}
            />
            <BlockGradient poke={poke} />
          </div>
        </div>
      </div>
    </PokeContext.Provider>
  );
};

export default StepPreview;
