import axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoints
const EP_ANALYTICS_GET = '/segment/analytics';
const EP_ANALYTICS_HOURLY_GET = '/segment/analytics/hourly';

// Methods
export const getAnalytics = ({startDate, endDate}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_GET, {params: {projectId}})
    .then((response) => response.data);
};
export const getAnalyticsHourly = ({startDate, endDate}) => {
  const projectId = generalSelector.getProject().uid;

  return axios
    .get(EP_ANALYTICS_HOURLY_GET, {params: {projectId}})
    .then((response) => response.data);
};
