import classNames from 'classnames';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import Lottie from 'lottie-react';
import React, {useContext, useEffect, useState} from 'react';
import {
  TYPE_HOTSPOT,
  TYPE_MODAL,
  TYPE_SNIPPET,
  TYPE_TOOLTIP,
} from 'scenes/Pushes/components/ModalCreatePoke/components/TemplatesModal/templates';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_SUCCESS,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import './_Styles.scss';
import drag from './animations/drag.json';
import loader from './animations/loading-steps-elements.json';

const Choice =
  'https://assets.usejimo.com/logic-view-content/survey/choice.svg';
const Concept =
  'https://assets.usejimo.com/logic-view-content/survey/concept.svg';
const Interview =
  'https://assets.usejimo.com/logic-view-content/survey/interview.svg';
const Nps = 'https://assets.usejimo.com/logic-view-content/survey/nps.svg';
const Open = 'https://assets.usejimo.com/logic-view-content/survey/open.svg';
const Scale = 'https://assets.usejimo.com/logic-view-content/survey/scale.svg';
const Slider =
  'https://assets.usejimo.com/logic-view-content/survey/slider.svg';
const Say = 'https://assets.usejimo.com/logic-view-content/survey/text.svg';
const ThankYou = 'https://assets.usejimo.com/logic-view-content/survey/ty.svg';

const SpeechBubble =
  'https://assets.usejimo.com/logic-view-content/tour/bubblespeech.svg';
const Celebration =
  'https://assets.usejimo.com/logic-view-content/tour/celebration.svg';
const Cursor = 'https://assets.usejimo.com/logic-view-content/tour/cursor.svg';
const Modal = 'https://assets.usejimo.com/logic-view-content/tour/modal.svg';
const Pointer =
  'https://assets.usejimo.com/logic-view-content/tour/pointer.svg';
const Badge =
  'https://assets.usejimo.com/logic-view-content/tour/pulsating.svg';
const Snippet =
  'https://assets.usejimo.com/logic-view-content/tour/snippet.svg';
const Sticker =
  'https://assets.usejimo.com/logic-view-content/tour/sticker.svg';
const Tooltip =
  'https://assets.usejimo.com/logic-view-content/tour/tooltip.svg';

const propTypes = {};

const SurveyBlocks = [
  {
    title: 'NPS',
    image: Nps,
    type: STEP_TYPE_NPS,
    category: 'Ask',
  },
  {
    title: 'Multiple Choice',
    image: Choice,
    type: STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    category: 'Ask',
  },
  {
    title: 'Slider',
    image: Slider,
    type: STEP_TYPE_SLIDER,
    category: 'Ask',
  },
  {
    title: 'Opinion Scale',
    image: Scale,
    type: STEP_TYPE_OPINION_SCALE,
    category: 'Ask',
  },
  {
    title: 'Open Question',
    image: Open,
    type: STEP_TYPE_TEXT_LONG,
    category: 'Ask',
  },
  {
    title: 'Concept Test',
    image: Concept,
    type: STEP_TYPE_CONCEPT_TEST,
    category: 'Test',
  },
  {
    title: 'Interview Prompt',
    image: Interview,
    type: STEP_TYPE_INTERVIEW,
    category: 'Test',
  },
  {
    title: 'Text Block',
    image: Say,
    type: STEP_TYPE_TEXT_BLOCK,
    category: 'Say',
  },
  {
    title: 'Thank You',
    image: ThankYou,
    type: STEP_TYPE_SUCCESS,
    category: 'Say',
  },
];

const TourBlocks = [
  {
    title: 'Tooltip',
    image: Tooltip,
    type: TYPE_TOOLTIP,
    category: 'Guide',
  },
  {
    title: 'Snippet',
    image: Snippet,
    type: TYPE_SNIPPET,
    category: 'Guide',
  },
  {
    title: 'Pointer',
    image: Pointer,
    category: 'Guide',
  },
  {
    title: 'Cursor',
    image: Cursor,
    category: 'Guide',
  },
  {
    title: 'Modal',
    image: Modal,
    type: TYPE_MODAL,
    category: 'Announce',
  },
  {
    title: 'Badge',
    image: Badge,
    type: TYPE_HOTSPOT,
    category: 'Announce',
  },
  {
    title: 'Sticker',
    image: Sticker,
    category: 'Announce',
  },
  {
    title: 'Speech bubble',
    image: SpeechBubble,
    category: 'Engage',
  },
  {
    title: 'Celebration',
    image: Celebration,
    category: 'Engage',
  },
];

export const ContentLoader = () => {
  return (
    <Lottie
      animationData={loader}
      className={classNames('animation-loader')}
      loop={true}
    />
  );
};

export const StepSectionItem = ({
  title,
  image,
  data,
  onClick,
  onLoad = () => {},
  onError = () => {},
}) => {
  const {setIsDraggingToAddStep} = useContext(BuilderContext);

  const onDragStart = (event) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
    setIsDraggingToAddStep(true);
  };

  const onDragEnd = () => {
    setIsDraggingToAddStep(false);
  };

  return (
    <div
      className={classNames('section-item', {
        'is-disabled': data.type == null,
      })}>
      <div className="draggable-wrapper">
        <div
          className="image-wrapper"
          draggable
          onDragStart={onClick != null ? null : onDragStart}
          onDragEnd={onClick != null ? null : onDragEnd}
          onClick={onClick || (() => {})}>
          <img src={image} alt="img" onLoad={onLoad} onError={onError} />
        </div>
      </div>
      <div className="title subtitle-4 n-800">{title}</div>
    </div>
  );
};

const ContentManager = () => {
  const {evolution} = useContext(BuilderContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loadedCount, setLoadedCount] = useState(0);

  const isTour = evolution.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution.type === EVOLUTION_TYPE_SURVEY;

  const imagesLength = isTour ? TourBlocks.length : SurveyBlocks.length;

  useEffect(() => {
    if (loadedCount === imagesLength) {
      setIsLoading(false);
    }
  }, [loadedCount, imagesLength]);

  const handleImageLoad = () => {
    setLoadedCount((prev) => prev + 1);
  };

  const renderItem = (block) => {
    return (
      <StepSectionItem
        key={block.title}
        title={block.title}
        image={block.image}
        data={{type: block.type}}
        onLoad={handleImageLoad}
        onError={handleImageLoad}
      />
    );
  };

  return (
    <div className="content-manager-wrapper">
      <div className="drag-and-drop-help n-700 body-4">
        <Lottie
          animationData={drag}
          className={classNames('animation-drag')}
          loop={true}
        />
        Drag & drop to add a step
      </div>
      <Divider />
      {isLoading === true && <ContentLoader />}
      <div
        className={classNames('content-manager', {
          'is-loading': isLoading,
        })}>
        {isTour && (
          <div className="content-wrapper">
            <div className="sections">
              <div className="section">
                <div className="section-title subtitle-3 n-700">Guide</div>
                <div className="section-list">
                  {TourBlocks.filter((block) => block.category === 'Guide').map(
                    (block) => renderItem(block)
                  )}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Announce</div>
                <div className="section-list">
                  {TourBlocks.filter(
                    (block) => block.category === 'Announce'
                  ).map((block) => renderItem(block))}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Engage</div>
                <div className="section-list">
                  {TourBlocks.filter(
                    (block) => block.category === 'Engage'
                  ).map((block) => renderItem(block))}
                </div>
              </div>
            </div>
          </div>
        )}
        {isSurvey && (
          <div className="content-wrapper">
            <div className="sections">
              <div className="section">
                <div className="section-title subtitle-3 n-700">Ask</div>
                <div className="section-list">
                  {SurveyBlocks.filter((block) => block.category === 'Ask').map(
                    (block) => renderItem(block)
                  )}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Test</div>
                <div className="section-list">
                  {SurveyBlocks.filter(
                    (block) => block.category === 'Test'
                  ).map((block) => renderItem(block))}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title subtitle-3 n-700">Say</div>
                <div className="section-list">
                  {SurveyBlocks.filter((block) => block.category === 'Say').map(
                    (block) => renderItem(block)
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ContentManager.propTypes = propTypes;

export default ContentManager;
