import classnames from 'classnames';
import React, {memo, useContext, useEffect, useRef, useState} from 'react';
import {hasFlag} from '../../helpers/bitwise';
import '../../icons/_Icons.scss';
import './_styles.scss';
import {BlockAnimation} from './components/BlockAnimation';
import {BlockDismissCross} from './components/BlockDismissCross';
import {LayoutBanner} from './components/LayoutBanner';
import LayoutBottom from './components/LayoutBottom';
import {LayoutConcept} from './components/LayoutConcept';
import LayoutMiddle from './components/LayoutMiddle';
import LayoutTop from './components/LayoutTop';
import {
  BLOCK_ANIMATION,
  BLOCK_CHOICE,
  BLOCK_CONCEPT,
  getDefaultResponseForStep,
  isInteractiveBlock,
} from './constants/blocks';
import {F_SLOT_HINT, F_SLOT_TOP_BAR} from './constants/poke';
import {PokeContext, PokeStateContext} from './context';
import {useDimension} from './hooks/useDimension';
import usePrevious from './hooks/usePrevious';
import {EVALUATE_COMPLETED, EVALUATE_DISMISS, evaluate} from './lib/conditions';
import {delay} from './utils/delay';
import {useContainerStyle} from './utils/get-poke-container-style';

export const F_STEP_BLOCK_CLOSE_DISABLED = 1;
export const F_STEP_HEIGHT_CUSTOM = 2;
export const F_STEP_DISCOVERY_STEP_REQUIRED = 4;
export const F_OPTION_POKE_CARD_WITH_POINTER = 131072;

export const FADE_OUT_DELAY = 500;
export const JIMO_LABEL_HEIGHT = 34;
export const NO_JIMO_LABEL_EXTRA_HEIGHT = 24;

export const Poke = memo(
  ({
    inBuilder = false, // component is used in builder (Dalaran)
    inConcept = false,
    JimoLabel = null,
    AnalyticViewTrigger = null,
    onClose = () => {},
    onView = () => {},
    onWidgetOpen = () => {},
    onConceptOpen = () => {},
    onConceptClose = () => {},
    onBookingOpen = () => {},
    onContainerStyleChange = () => {},
    onBlockSelected = () => {},
    onCtaClick = () => {},
    onSurveyResponseCreate = () => {},
    onSurveyComplete = () => {},
    onGoToPreviousPoke = () => {},
    onGradientStyleOverwriteChange = () => {},
    onUrlClick = () => {},
    onCurrentStepChange = () => {},
    onImageClick = () => {},
    selectedBlock = null,
    disableAnimations = false,
    language = null,
    forwardRef,
    onDimensionChange = () => {},
    isLastTourStep = false,
    isTour = false,
    experienceType = null,
    isBookingOpen = false,
    onTriggerActions = () => {},
    addFontFamily = () => {},
  }) => {
    const {
      poke,
      forcedStep,
      currentStepIndex: forceCurrentStepIndex = 0,
      currentConceptStepIndex: forceCurrentConceptStepIndex = 0,
    } = useContext(PokeContext);

    const [state, setState] = useState({
      currentStepIndex: forceCurrentStepIndex,
      currentConceptStepIndex: forceCurrentConceptStepIndex,
      gradientStyleOverwrite: null, // [color1, color2]
      goingToStepIndex: null,
      response: undefined,
      playAnimationConceptOut: false,
      blocksReadyCount: 0, // use to refresh height calculation each time it's getting increased
    });
    const [prevStepsById, setPrevStepsById] = useState({});

    const pokeRef = useRef();
    const currentStepRef = useRef();

    const previousInConcept = usePrevious(inConcept);

    /** Analytics : send view on init */
    useEffect(() => {
      onView(poke);
    }, []);

    useEffect(() => {
      if (forwardRef != null) {
        forwardRef.current = pokeRef?.current;
      }
    }, [pokeRef?.current]);

    /** Builder : listen to current step changes */
    useEffect(() => {
      setState((state) => {
        return {
          ...state,
          currentStepIndex: forceCurrentStepIndex,
          currentConceptStepIndex: forceCurrentConceptStepIndex,
        };
      });
    }, [forceCurrentStepIndex, forceCurrentConceptStepIndex]);

    useEffect(() => {
      if (forcedStep != null) {
        const stepIndex = poke.steps.findIndex(
          (s) => s.uid === forcedStep.stepId
        );
        if (stepIndex >= 0) {
          setPrevStepsById((prev) => ({
            ...prev,
            [forcedStep.stepId]: forcedStep.currentStep.uid,
          }));
          setState((state) => {
            return {
              ...state,
              currentStepIndex: stepIndex,
            };
          });
        }
      }
    }, [forcedStep]);

    const pokeHeight = pokeRef?.current?.clientHeight;
    const pokeWidth = pokeRef?.current?.clientWidth;

    /** Poke : Current step */
    const currentStep =
      inConcept === true
        ? poke.steps[state.currentStepIndex].prototypes[0]?.steps[
            state.currentConceptStepIndex
          ]
        : poke.steps[state.currentStepIndex];
    const currentConcept =
      inConcept === true
        ? poke.steps[state.currentStepIndex].blocks.find(
            (b) => b.type === BLOCK_CONCEPT
          )
        : null;

    /** Container : get style */
    const containerStyle = useContainerStyle();

    /** Container : send dimensions */
    const dimensions = useDimension({step: currentStep});

    // Alternative way to get dimensions, might be useful for future
    // useEffect(() => {
    //   const observer = new ResizeObserver((entries) => {
    //     for (let entry of entries) {
    //       onDimensionChange([entry.contentRect.width, entry.contentRect.height]);
    //     }
    //   });

    //   if (pokeRef.current) {
    //     observer.observe(pokeRef.current);
    //   }

    //   return () => observer.disconnect();
    // }, [pokeRef.current]);

    useEffect(() => {
      if (!pokeHeight || !pokeWidth) {
        return;
      }
      onDimensionChange([dimensions[0], pokeHeight]);
    }, [pokeHeight, pokeWidth, dimensions?.[0]]);

    useEffect(() => {
      currentStepRef.current = currentStep;
      onCurrentStepChange(currentStep);
    }, [currentStep]);

    /** Container : send style */
    useEffect(() => {
      onContainerStyleChange(containerStyle);
    }, [state.currentStepIndex, poke.style]);

    /** Concept : Go to next step after closing and reset index */
    useEffect(() => {
      if (inConcept === false && previousInConcept === true) {
        setState((state) => ({
          ...state,
          currentConceptStepIndex: 0,
        }));
        if (inBuilder === false) {
          goToNextStep();
          return;
        }
      }
    }, [inConcept]);

    /** Response : in builder, reset response every time step changed to prevent crash because of different format of response */
    useEffect(() => {
      if (inBuilder === true) {
        updateState({
          response: undefined,
        });
      }
    }, [forceCurrentStepIndex, forceCurrentConceptStepIndex]);

    useEffect(() => {
      onGradientStyleOverwriteChange(state.gradientStyleOverwrite);
    }, [onGradientStyleOverwriteChange, state.gradientStyleOverwrite]);

    /** Methods */
    const updateState = (data) => {
      setState((state) => ({
        ...state,
        ...data,
      }));
    };
    const goToNextStep = async ({stepIndex = null, callbackActions} = {}) => {
      if (
        inConcept === false &&
        state.currentStepIndex >= poke.steps.length - 1
      ) {
        return closePoke({callbackActions});
      }
      if (
        inConcept === true &&
        state.currentConceptStepIndex >=
          poke.steps[state.currentStepIndex].prototypes[0].steps.length - 1
      ) {
        return closeConcept();
      }
      // Prevent executing multiple time because of spam click
      if (state.goingToStepIndex != null) {
        return;
      }
      const newStepIndex =
        stepIndex != null
          ? stepIndex
          : inConcept === true
          ? state.currentConceptStepIndex + 1
          : state.currentStepIndex + 1;

      // store current step index in prevStepsById to be able to go back to it
      const newStepId =
        inConcept === true
          ? poke.steps[state.currentStepIndex].prototypes[0]?.steps[
              newStepIndex
            ]?.uid
          : poke.steps[newStepIndex].uid;
      if (inConcept === true && newStepId == null) {
        return closePoke({callbackActions});
      }

      onTriggerActions(callbackActions);

      setPrevStepsById((prev) => ({
        ...prev,
        [newStepId]: currentStepRef.current.uid,
      }));
      updateState({goingToStepIndex: newStepIndex});
      await delay(disableAnimations === true ? 0 : FADE_OUT_DELAY);
      setState((state) => ({
        ...state,
        response: undefined,
        ...(inConcept === true
          ? {currentConceptStepIndex: newStepIndex}
          : {currentStepIndex: newStepIndex}),
        goingToStepIndex: null,
      }));
    };
    const goToPrevStep = async ({callbackActions} = {}) => {
      // Prevent executing multiple time because of spam click
      if (state.goingToStepIndex != null) {
        return;
      }

      const prevStepById = prevStepsById[currentStepRef.current.uid];

      // if prevStepById is defined, means we're coming from a following step or from a trigger
      if (prevStepById != null) {
        // look if the step is in the current evolution
        const prevStepIndex = poke.steps.findIndex(
          (s) => s.uid === prevStepById
        );

        if (prevStepIndex >= 0) {
          updateState({goingToStepIndex: prevStepIndex});
          await delay(disableAnimations === true ? 0 : FADE_OUT_DELAY);
          setState((state) => ({
            ...state,
            currentStepIndex: prevStepIndex,
            goingToStepIndex: null,
          }));
          return;
        } else {
          // if not, navigate to the correct evolution
          return onGoToPreviousPoke({callbackActions, stepId: prevStepById});
        }
      }
      // if prevStepById is not defined, means we're coming from a previous step
      else {
        if (state.currentStepIndex === 0) {
          // might want to check if there is a previous poke here
          return onGoToPreviousPoke({callbackActions});
        }
      }
    };
    const closeConcept = async () => {
      setState((state) => ({
        ...state,
        playAnimationConceptOut: true,
      }));
      await delay(600);
      return onConceptClose();
    };
    const closePoke = ({forceDismiss = false, callbackActions} = {}) => {
      const isTourExperience = experienceType === 'TOUR';

      const data = {
        poke,
        step: currentStep,
        stepIndex: state.currentStepIndex,
        callbackActions,
      };

      const isTourCompleted =
        isTourExperience === true && isLastTourStep === true;

      if (isTour === true) {
        if (forceDismiss === true) {
          data.isTourExited = true;
          data.isTourCompleted = isTourCompleted;
        } else {
          data.isTourCompleted = isTourCompleted;
        }
      }

      onClose(data);
    };
    const updateResponse = (response) => {
      // Response are stored at top level so that CTA can trigger submission of response
      updateState({
        ...state,
        response,
      });
    };

    const submitResponse = async ({
      response = state.response ?? getDefaultResponseForStep(currentStep),
      blockInteractive = currentStep.blocks.find((b) =>
        isInteractiveBlock(b.type)
      ),
      callbackActions,
    }) => {
      if (blockInteractive == null) {
        return console.warn(
          'Cannot submit response without interactive block in the current step'
        );
      }

      const steps =
        inConcept === true
          ? poke.steps[state.currentStepIndex].prototypes[0].steps
          : poke.steps;
      const {
        state: conditionResultState,
        stepIndex: nextStepIndex,
        actions,
      } = response != null
        ? evaluate({
            steps,
            step: currentStep,
            stepIndex:
              inConcept === true
                ? state.currentConceptStepIndex
                : state.currentStepIndex,
            response,
          })
        : {};

      if (inBuilder !== true) {
        onSurveyResponseCreate({
          evolutionId: poke.uid,
          stepId: currentStep.uid,
          // BlockInteractive can be null if the step is not interactive but triggered a submitResponse because of modeOnlyComplete
          ...(blockInteractive.type === BLOCK_CHOICE
            ? {
                selectedOptions: response,
              }
            : {
                value: response != null ? response.toString() : null,
              }),
        }).then(() => {
          if (conditionResultState === EVALUATE_COMPLETED) {
            return completeSurvey();
          }
        });
      }
      if (inConcept === true) {
        // Check if concept test has any steps after this one
        if (
          conditionResultState === EVALUATE_COMPLETED ||
          poke.steps.length === state.currentStepIndex + 1
        ) {
          setState((state) => ({
            ...state,
            playAnimationConceptOut: true,
          }));
          await delay(600);
          return onConceptClose();
        } else {
          return goToNextStep({callbackActions});
        }
      }
      if (conditionResultState === EVALUATE_DISMISS) {
        return closePoke({
          callbackActions: [...(callbackActions || []), ...(actions || [])],
        });
      }
      if (conditionResultState === EVALUATE_COMPLETED) {
        if (nextStepIndex == null) {
          return closePoke({
            callbackActions: [...(callbackActions || []), ...(actions || [])],
          });
        }
      }
      return goToNextStep({
        stepIndex: nextStepIndex,
        callbackActions: [...(callbackActions || []), ...(actions || [])],
      });
    };

    const completeSurvey = ({
      callbackActions = [],
      shouldClosePoke = false,
    } = {}) => {
      onSurveyComplete({
        evolutionId: poke.uid,
      });
      if (shouldClosePoke === true) {
        return closePoke({
          callbackActions: [...callbackActions],
        });
      }
    };

    const updateBlocksReady = () => {
      setState((state) => {
        return {
          ...state,
          blocksReadyCount: state.blocksReadyCount + 1,
        };
      });
    };

    if (currentStep?.blocks == null) {
      return null;
    }

    /** Variables */
    const {style} = poke;
    const {background, borderRadius} = style ?? {};
    const shouldShowBlockAnimation =
      currentStep.blocks?.findIndex((b) => b.type === BLOCK_ANIMATION) >= 0;
    const isBanner = hasFlag(F_SLOT_TOP_BAR, poke.boostFlags);

    // Load Tangerine & Cantarell
    return (
      <PokeStateContext.Provider
        value={{
          ...state,
          experienceType,
          currentStep,
          currentConcept,
          dimensions,
          inBuilder,

          updateState,
          goToNextStep,
          goToPrevStep,
          close: closePoke,
          closeConcept,
          updateResponse,
          submitResponse,
          completeSurvey,
          updateBlocksReady,

          onBookingOpen,
          onBlockSelected,
          onConceptOpen,
          onWidgetOpen,
          onCtaClick,
          onUrlClick,
          onImageClick,

          poke,
          selectedBlock,

          language,

          isBookingOpen,

          onTriggerActions,

          addFontFamily,
        }}>
        <div className="jimo-poke-wrapper">
          <div
            ref={pokeRef}
            className={classnames('jimo-poke', {
              'has-jimo-label': JimoLabel != null,
              'is-poke-banner': hasFlag(F_SLOT_TOP_BAR, poke.boostFlags),
              'is-hint': hasFlag(F_SLOT_HINT, poke.boostFlags),
              'in-concept': inConcept === true,
              'has-animations-disabled': disableAnimations === true,
              'has-custom-height': hasFlag(
                F_STEP_HEIGHT_CUSTOM,
                currentStep?.stepFlags
              ),
            })}
            style={{
              ...(inConcept === true
                ? {
                    borderRadius,
                    ...(background?.type === 'color'
                      ? {backgroundColor: background?.primaryColor}
                      : background?.animated === true
                      ? {
                          backgroundColor: background?.secondaryColor || '#fff',
                        }
                      : {}),
                  }
                : {}),
              ...(inConcept !== true &&
              (currentStep?.style?.width || dimensions[0] > 0)
                ? {
                    width: currentStep?.style?.width || dimensions[0],
                  }
                : {}),
              ...(hasFlag(F_STEP_HEIGHT_CUSTOM, currentStep?.stepFlags) === true
                ? {
                    height: currentStep?.style?.height,
                  }
                : {}),
            }}
            onClick={() => {
              onBlockSelected(null);
            }}>
            {/* Layouts (for banner) */}
            {isBanner === true && (
              <>
                <LayoutBanner />
              </>
            )}
            {/* Layouts (for concept) */}
            {inConcept === true && (
              <LayoutConcept>
                <LayoutTop />
                <LayoutMiddle />
                <LayoutBottom JimoLabel={JimoLabel} />
                {shouldShowBlockAnimation === true && (
                  <BlockAnimation key={state.currentStepIndex} />
                )}
              </LayoutConcept>
            )}
            {/* Layouts (not banner and not concept) */}
            {isBanner === false && inConcept === false && (
              <>
                <LayoutTop />
                <LayoutMiddle />
                <LayoutBottom JimoLabel={JimoLabel} />

                {/* Fixed content */}
                <BlockDismissCross />
                {shouldShowBlockAnimation === true && (
                  <BlockAnimation key={state.currentStepIndex} />
                )}
              </>
            )}
            {/* Analytics */}
            {AnalyticViewTrigger != null && (
              <AnalyticViewTrigger
                key={currentStep.uid}
                currentStep={currentStep}
                isFirstStep={state.currentStepIndex === 0}
              />
            )}
          </div>
        </div>
      </PokeStateContext.Provider>
    );
  }
);

Poke.displayName = 'Poke';
