import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Input from 'components/Input';
import {IntegrationGTM} from 'components/IntegrationGTM';
import {toastSuccess} from 'components/Toaster';
import {TrackingCodeWithStyle} from 'components/TrackingCode';
import {addFlag} from 'helpers/bitwise';
import {isChrome, useIsExtensionInstalled} from 'helpers/utils';
import Lottie from 'lottie-react';
import {useEffect, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_GET_STARTED,
  ROUTE_ONBOARDING_DETAILS_STEP_2_3,
} from 'router/routes.const';
import {ListElem} from 'scenes/PokeBuilder/components/LiveEditWarningModal';
import {generalSelector} from 'selectors';
import {meService} from 'services';
import {
  ME_F_ONBOARDING_COMPLETED,
  ME_F_ONBOARDING_DONE_STEP_3_1,
} from 'services/me';
import confettiAnimation from './animations/confetti.json';
import Browsers from './imgs/browsers.png';
import logoGTM from './imgs/logo-gtm.svg';
import './step-3-1.scss';

const INSTALL_GTM = 'INSTALL_GTM';
const INSTALL_CODE = 'INSTALL_CODE';
const INSTALL_SEND = 'INSTALL_SEND';

const TAB_JS = 'JS';
const TAB_REACT = 'REACT';
const TAB_VUE = 'VUE';
const TAB_ANGULAR = 'ANGULAR';

export const MODE_INSTALL = 'MODE_INSTALL';
export const MODE_TRY = 'MODE_TRY';

export const BrowsersIcons = Browsers;

export const OnboardingDetailsStep31 = ({
  triggerPTLAnimation,
  triggerOnboardingEnd,
  triggerSquareAnimationOut,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => generalSelector.getUser(state));
  const project = useSelector((state) => generalSelector.getProject(state));

  const uptUser = (data) => dispatch(generalActions.uptUser(data));

  const [playAnimationOut, setPlayAnimationOut] = useState(false);
  const [mode, setMode] = useState(isChrome() === true ? null : MODE_INSTALL);

  const {isInstalled, check} = useIsExtensionInstalled();

  const handleGoBack = async () => {
    setPlayAnimationOut(true);
    setTimeout(() => {
      triggerPTLAnimation('out', () => {
        history.push(ROUTE_ONBOARDING_DETAILS_STEP_2_3);
      });
    }, 1200);
  };
  const handleGoBackToModes = async () => {
    setPlayAnimationOut(true);
    setTimeout(() => {
      setMode(null);
      setPlayAnimationOut(false);
    }, 1000);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const onboardingFlags = addFlag(
      [ME_F_ONBOARDING_DONE_STEP_3_1, ME_F_ONBOARDING_COMPLETED],
      user.onboardingFlags
    );

    await meService.updateOnboardingFlag(onboardingFlags);
    await meService.welcomeMe(project.uid);
    uptUser({onboardingFlags});
    setPlayAnimationOut(true);
    triggerSquareAnimationOut();
    setTimeout(() => {
      triggerOnboardingEnd(() => {
        history.push(`${ROUTE_GET_STARTED}?et-voila=true`);
      });
    }, 200);
  };

  useEffect(() => {
    triggerPTLAnimation('in', () => {});
  }, []);

  useEffect(() => {
    window.addEventListener('focus', check);
    return () => {
      window.removeEventListener('focus', check);
    };
  }, []);

  return (
    <div
      className={classnames(
        's-onboarding-details-step s-onboarding-details-step-3-1',
        {
          'is-exiting': playAnimationOut,
        }
      )}>
      <div className="install-extension-wrapper">
        {isInstalled === true ? (
          <>
            <h1>Well done! Everything is ready.</h1>
            <p>
              Quick reminder : You won't be able to display anything to your
              users until Jimo is fully installed.
            </p>
            <div className="extension-installed-wrapper">
              <div className="extension-installed">
                <div className="confetti-wrapper">
                  <Lottie
                    animationData={confettiAnimation}
                    loop={false}></Lottie>
                </div>
                <i className="icon-tick"></i> Chrome extension installed
              </div>
              <p>
                You can now build and test Jimo before installing it in your
                app.
              </p>
            </div>
          </>
        ) : (
          <>
            <h1>Install it in one click, and test.</h1>
            <p>
              Create and preview in-app interactions using our Jimo Chrome
              extension.
              <br />
              To test before deploying the full Jimo installation to your
              platform.
            </p>

            <div className="card-install-extension-wrapper">
              <div className="content">
                <ListElem
                  type="safe"
                  title="Test in-app experiences without a full installation"
                  subtitle="No need to install Jimo on your app, preview flows instantly in your browser"
                />
                <ListElem
                  type="safe"
                  title="Build and preview flows with real-time visuals"
                  subtitle="Create interactive guides and see exactly how they’ll look in-app"
                />
                <ListElem
                  type="safe"
                  title="Showcase and present Jimo’s potential to your team"
                  subtitle="Use it as a powerful demo tool to get internal buy-in"
                />
                <ListElem
                  type="safe"
                  title="Nothing will be shown to your users"
                  subtitle="Use it as a powerful demo tool to get internal buy-in"
                />
              </div>
              <div className="card-install-extension-footer">
                <a
                  href="https://chrome.google.com/webstore/detail/jimo/koipflmbgiibbkcfccgpocdgifapofje?hl=en&authuser=0"
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button cta primary className="btn-install-extension">
                    Install Jimo extension
                  </Button>
                </a>
                <div className="card-install-extension-footer-text body-4 n-600">
                  Jimo extension works on:
                  <img src={Browsers} alt="browsers" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="details-actions">
        <Button cta onClick={mode != null ? handleGoBackToModes : handleGoBack}>
          Back
        </Button>
        <Button
          className="btn-continue"
          cta
          primary
          secondary
          iconRight="icon-chevron-right"
          onClick={handleSubmit}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export const Install = ({
  withoutAnimation = false,
  defaultOpen = INSTALL_CODE,
}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const [expanded, setExpanded] = useState(defaultOpen);
  const [tab, setTab] = useState(TAB_JS);
  const [inputEmail, setInputEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRequestTechHelp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await meService.requestTechHelp(project.uid, inputEmail).then(() =>
      toastSuccess(['Sent!', 'Your tech team is now aware of what to do!'], {
        toastId: 'request-tech-help-sent',
      })
    );
    setIsSubmitting(false);
  };

  return (
    <div
      className={classnames('install-collapses-wrapper', {
        'without-animation': withoutAnimation === true,
      })}>
      <div
        className={classnames('item', {
          'is-expanded': expanded === INSTALL_GTM,
        })}>
        <div
          className="item-header"
          onClick={() =>
            setExpanded(expanded === INSTALL_GTM ? null : INSTALL_GTM)
          }>
          <img src={logoGTM} alt="logo GTM" />
          <div className="item-header-texts">
            Setup with Google Tag Manager
            <small>Start interacting quickly with our GTM integration</small>
          </div>
          <i className="icon-chevron-bottom"></i>
        </div>
        <AnimateHeight
          height={expanded === INSTALL_GTM ? 'auto' : 0}
          duration={300}>
          <div className="item-body">
            <IntegrationGTM></IntegrationGTM>
          </div>
        </AnimateHeight>
      </div>
      <div
        className={classnames('item', {
          'is-expanded': expanded === INSTALL_CODE,
        })}>
        <div
          className="item-header"
          onClick={() =>
            setExpanded(expanded === INSTALL_CODE ? null : INSTALL_CODE)
          }>
          <i className="icon-code"></i>
          <div className="item-header-texts">
            Install your Jimo snippet
            <small>Past your Jimo snippet in the head of your website</small>
          </div>
          <i className="icon-chevron-bottom"></i>
        </div>
        <AnimateHeight
          height={expanded === INSTALL_CODE ? 'auto' : 0}
          duration={300}>
          <div className="item-body">
            <div className="onboarding-install-tabs">
              <Button
                iconLeft="icon-vanilla-js"
                className={tab === TAB_JS ? 'selected' : ''}
                onClick={() => setTab(TAB_JS)}>
                Javascript
              </Button>
              <Button
                iconLeft="icon-react-js"
                className={tab === TAB_REACT ? 'selected' : ''}
                onClick={() => setTab(TAB_REACT)}>
                React
              </Button>
              <Button
                iconLeft="icon-vue-js"
                className={tab === TAB_VUE ? 'selected' : ''}
                onClick={() => setTab(TAB_VUE)}>
                Vue
              </Button>
              <Button
                iconLeft="icon-angular-js"
                className={tab === TAB_ANGULAR ? 'selected' : ''}
                onClick={() => setTab(TAB_ANGULAR)}>
                Angular
              </Button>
            </div>
            <div className="item-snippet-wrapper">
              <TrackingCodeWithStyle
                framework={
                  tab === TAB_REACT
                    ? 'react'
                    : tab === TAB_ANGULAR
                    ? 'angular'
                    : tab === TAB_VUE
                    ? 'vue'
                    : ''
                }
                project={project}></TrackingCodeWithStyle>
            </div>
          </div>
        </AnimateHeight>
      </div>
      <div
        className={classnames('item', {
          'is-expanded': expanded === INSTALL_SEND,
        })}>
        <div
          className="item-header"
          onClick={() =>
            setExpanded(expanded === INSTALL_SEND ? null : INSTALL_SEND)
          }>
          <i className="icon-email"></i>
          <div className="item-header-texts">
            Send your snippet to a teammate
            <small>All instructions for your favorite engineer</small>
          </div>
          <i className="icon-chevron-bottom"></i>
        </div>
        <AnimateHeight
          height={expanded === INSTALL_SEND ? 'auto' : 0}
          duration={300}>
          <form className="item-body" onSubmit={handleRequestTechHelp}>
            <div className="item-email-label-wrapper">
              <div className="input-label">Email</div>
              <div className="input-btn-wrapper">
                <Input
                  type="email"
                  required
                  placeholder="hello.world@company.com"
                  value={inputEmail}
                  onChange={({target}) => setInputEmail(target.value)}></Input>
                <Button cta primary loading={isSubmitting}>
                  Send invite
                </Button>
              </div>
            </div>
          </form>
        </AnimateHeight>
      </div>
    </div>
  );
};
