import Button from 'components/Button';
import {Modal} from 'components/Modal';
import {toastDanger, toastSuccess} from 'components/Toaster';
import {errorHelpers} from 'helpers';
import {bool, func, object} from 'prop-types';
import {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {
  buildLogic,
  hasConditionInLogic,
  isEventValid,
} from 'scenes/SuccessTracker/components/Event/components/EventConditions/utils';
import {TrackerBuilderContext} from 'scenes/TrackerBuilder';
import TrackerBuilderSingleEvent from 'scenes/TrackerBuilder/components/TrackerBuilderSingleEvent';
import TrackerBuilderSteps from 'scenes/TrackerBuilder/components/TrackerSteps';
import {trackerService} from 'services';
import {
  TRACKER_TYPE_ACTIVATION_TRACKER,
  TRACKER_TYPE_FEATURE_USAGE,
  TRACKER_TYPE_GOAL,
} from 'services/tracker';
import {Swaler} from 'swaler';
import './_Styles.scss';

const propTypes = {
  isOpen: bool.isRequired,
  onRequestClose: func,
  tracker: object.isRequired,
  onSave: func,
};

const defaultProps = {
  onRequestClose: () => {},
  onSave: null,
};

const logger = new Swaler('EditTrackerDrawer');

const EditTrackerDrawer = ({
  isOpen,
  onRequestClose,
  tracker: trackerProps,
  onSave,
  rest,
}) => {
  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);

  const [tracker, setTracker] = useState(trackerProps);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [conditions, setConditions] = useState(
    JSON.parse(JSON.stringify(trackerProps.onTheFlyEvent?.conditions || []))
  );
  const [logic, setLogic] = useState(
    JSON.parse(
      JSON.stringify(trackerProps.onTheFlyEvent?.logic || buildLogic())
    )
  );

  useQuery({
    queryKey: ['tracker', tracker.uid],
    queryFn: () => trackerService.getTracker({trackerId: tracker.uid}),
    onSuccess: (tracker) => {
      setTracker(tracker);
      setConditions(
        JSON.parse(JSON.stringify(tracker.onTheFlyEvent?.conditions || []))
      );
      setLogic(
        JSON.parse(JSON.stringify(tracker.onTheFlyEvent?.logic || buildLogic()))
      );
    },
    refetchOnWindowFocus: false,
    enabled: tracker.uid != null,
  });

  const handleSave = async () => {
    if (tracker.onTheFlyEvent != null) {
      tracker.onTheFlyEvent.conditions = conditions.filter((c) =>
        hasConditionInLogic(logic, c.uid)
      );
      tracker.onTheFlyEvent.logic = logic;
    }

    if (onSave != null) {
      onSave(tracker);
      return;
    }

    setIsSaving(true);
    try {
      tracker.steps?.forEach((step, index) => {
        step.indexOrder = index;
      });

      await trackerService.updateTracker(tracker.uid, tracker);
      onRequestClose();
      toastSuccess('Tracker updated 👍', {toastId: 'tracker-updated'});
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);
      logger.error(`Failed updating tracker: ${code}`);
      toastDanger([title, message], {actions});
    } finally {
      setIsSaving(false);
    }
  };

  const trackerCopy = JSON.parse(JSON.stringify(tracker));

  const hasChanges = () => {
    if (trackerCopy.onTheFlyEvent != null) {
      trackerCopy.onTheFlyEvent.conditions = conditions.filter((c) =>
        hasConditionInLogic(logic, c.uid)
      );
      trackerCopy.onTheFlyEvent.logic = logic;
    }
    return JSON.stringify(trackerCopy) !== JSON.stringify(trackerProps);
  };

  const isActivationTracker = tracker.type === TRACKER_TYPE_ACTIVATION_TRACKER;
  const isFeatureUsageTracker = tracker.type === TRACKER_TYPE_FEATURE_USAGE;
  const isGoalTracker = tracker.type === TRACKER_TYPE_GOAL;

  let isValid = true;

  if (isFeatureUsageTracker === true || isGoalTracker === true) {
    if (tracker.onTheFlyEvent != null) {
      if (isEventValid(conditions, logic).isValid === false) {
        isValid = false;
      }
    } else {
      if (tracker.events?.[0] == null) {
        isValid = false;
      }
    }
  } else if (isActivationTracker === true) {
    if (tracker.steps?.length < 2) {
      isValid = false;
    }

    for (const step of tracker.steps) {
      if (step.onTheFlyEvent != null) {
        if (
          isEventValid(step.onTheFlyEvent.conditions, step.onTheFlyEvent.logic)
            .isValid === false
        ) {
          isValid = false;
        }
      } else {
        if (step.events?.length === 0) {
          isValid = false;
        }
      }
    }
  }

  const testableTracker = JSON.parse(JSON.stringify(tracker));

  if (testableTracker.onTheFlyEvent != null) {
    testableTracker.onTheFlyEvent.conditions = conditions.filter((c) =>
      hasConditionInLogic(logic, c.uid)
    );
    testableTracker.onTheFlyEvent.logic = logic;
  }

  const handleTestTracker = async () => {
    goToEditInApp({
      data: {
        title: 'Test your tracker',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'EVENT_TESTER',
          tracker: testableTracker,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  return (
    <TrackerBuilderContext.Provider
      value={{
        tracker,
        setTracker,
        selectedStepId,
        setSelectedStepId,
        selectedEventId,
        setSelectedEventId,
        onAddNewEventClick: () => {},
        isDrawer: true,
      }}>
      <Modal
        className="edit-tracker-drawer fade-in-right"
        title="Setup of your tracker"
        isOpen={isOpen}
        {...rest}
        onRequestClose={() => {
          onRequestClose();
        }}
        footer={
          <>
            <Button muted className="cancel-btn" onClick={handleTestTracker}>
              Test tracker
            </Button>
            <Button
              reverted
              primary
              onClick={() => handleSave()}
              loading={isSaving}
              disabled={!hasChanges() || isValid !== true}>
              Save
            </Button>
          </>
        }>
        <div className="edit-tracker-drawer-content fade-in">
          {tracker != null ? (
            isActivationTracker === true ? (
              <TrackerBuilderSteps compact />
            ) : isFeatureUsageTracker === true || isGoalTracker === true ? (
              <TrackerBuilderSingleEvent
                conditions={conditions}
                setConditions={setConditions}
                logic={logic}
                setLogic={setLogic}
                borderless
                compact
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </TrackerBuilderContext.Provider>
  );
};

EditTrackerDrawer.propTypes = propTypes;
EditTrackerDrawer.defaultProps = defaultProps;

export default EditTrackerDrawer;
