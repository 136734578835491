import Button from 'components/Button';
import {Modal} from 'components/Modal';
import React from 'react';
import {BrowsersIcons} from 'scenes/Onboarding/scenes/Details/step-3-1';
import {ListElem} from '../LiveEditWarningModal';
import './_Styles.scss';

const InstallModalFooter = () => {
  return (
    <>
      <a
        href="https://chrome.google.com/webstore/detail/jimo/koipflmbgiibbkcfccgpocdgifapofje?hl=en&authuser=0"
        target="_blank"
        rel="noopener noreferrer">
        <Button cta primary className="btn-install-extension">
          Install Jimo extension
        </Button>
      </a>
      <div className="install-modal-footer-text body-4 n-600">
        Jimo extension works on:
        <img src={BrowsersIcons} alt="browsers" />
      </div>
    </>
  );
};
export const NotOptimalBrowserModal = ({isOpen, onRequestClose}) => {
  return (
    <Modal
      className="not-optimal-browser-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closable={false}
      title="Why is this browser not optimal?"
      footer={<InstallModalFooter />}>
      <div className="content">
        <div className="body-3 n-700">
          For an optimized and full build experience we highly recommend to
          download Jimo extension and therefore switch to a chrome based browser
          such as Chrome, Arc, Opera...
        </div>
        <ListElem
          type="safe"
          title="Basic editing"
          subtitle="You can still use Jimo builder, but few functionalities might not be available"
        />
        <ListElem
          type="danger"
          title="Pinned previews"
          subtitle="Getting a screenshot preview with your targeted elements"
        />
        <ListElem
          type="danger"
          title="Auto tab-switching"
          subtitle="Jump between Jimo and your app effortlessly"
        />
      </div>
    </Modal>
  );
};

export const InstallExtensionModal = ({
  isOpen,
  onRequestClose,
  title = 'Edit your experience in-app',
}) => {
  return (
    <Modal
      className="install-extension-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closable={false}
      title={title}
      footer={<InstallModalFooter />}>
      <div className="content">
        <div className="body-3 n-700">
          To access powerful in-app preview & editing, you’ll need the Jimo
          extension.
        </div>
        <ListElem
          type="safe"
          title="Test in-app experiences without a full installation"
          subtitle="No need to install Jimo on your app, preview experiences instantly in your browser"
        />
        <ListElem
          type="safe"
          title="Build and preview flows with real-time visuals"
          subtitle="Create interactive guides and see exactly how they’ll look in-app"
        />
        <ListElem
          type="safe"
          title="Showcase and present Jimo’s potential to your team"
          subtitle="Use it as a powerful demo tool to get internal buy-in"
        />
        <ListElem
          type="danger"
          title="Nothing will be shown to your users"
          subtitle="Jump between Jimo and your app effortlessly"
        />
      </div>
    </Modal>
  );
};

export const ImproveBuildingModal = ({isOpen, onRequestClose}) => {
  return (
    <Modal
      className="improve-building-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closable={false}
      title="Improve your building experience"
      footer={<InstallModalFooter />}>
      <div className="content">
        <div className="body-3 n-700">
          To access powerful in-app preview & editing, you’ll need the Jimo
          extension.
        </div>
        <ListElem
          type="safe"
          title="Auto tab-switching"
          subtitle="You can still use Jimo builder, but few functionalities might not be available"
        />
        <ListElem
          type="safe"
          title="Pinned previews"
          subtitle="Getting a screenshot preview with your targeted elements"
        />
      </div>
    </Modal>
  );
};
